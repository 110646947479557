define("travis/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    animation: Ember.inject.service(),
    animationDuration: Ember.computed.reads('animation.durations.quick'),
    transition: Ember.computed.reads('animation.transitions.fade'),
    closeOnClickOverlay: true,
    closeButton: false,
    isVisible: true,
    position: 'fixed',
    lastClickInside: false,
    onClose: function onClose() {},
    onClickOverlay: function onClickOverlay() {
      if (!this.lastClickInside && this.closeOnClickOverlay) {
        this.onClose();
      }

      this.lastClickInside = false;
    },
    onClickModal: function onClickModal(event) {
      this.lastClickInside = true;
    }
  });

  _exports.default = _default;
});