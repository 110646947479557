define("travis/controllers/organization/plan_usage", ["exports", "travis/mixins/controller/plan_usage"], function (_exports, _plan_usage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_plan_usage.default, {});

  _exports.default = _default;
});