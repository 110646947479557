define("travis/templates/layouts/center-max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "civ+C4kb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"wrapper centered\"],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[],[]]],[0,\"\\n  \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"main\"],[10,\"class\",\"main main-max\"],[10,\"role\",\"main\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inner-max\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/layouts/center-max.hbs"
    }
  });

  _exports.default = _default;
});