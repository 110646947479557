define("travis/components/travis-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['layoutClass'],
    layoutName: '',
    activeModel: null,
    model: Ember.computed.reads('activeModel'),
    layoutClass: Ember.computed('layoutName', function () {
      return this.layoutName.replace('layouts/', 'layout--');
    })
  });

  _exports.default = _default;
});