define("travis/templates/components/flashes/scheduled-plan-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mCDG2IX+",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"flash ember-view\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"notice below-top-bar ember-view\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"flash-message\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n        You have selected the \"],[1,[23,1,[\"scheduledPlan\",\"name\"]],false],[0,\" plan. Your new plan will be effective from \"],[1,[28,\"pretty-date\",[[23,1,[\"date\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flashes/scheduled-plan-change.hbs"
    }
  });

  _exports.default = _default;
});