define("travis/templates/scan-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nbQ0ATgS",
    "block": "{\"symbols\":[],\"statements\":[[5,\"scan-result-details\",[],[[\"@scanResult\",\"@repo\"],[[23,0,[\"scanResult\"]],[23,0,[\"repo\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/scan-result.hbs"
    }
  });

  _exports.default = _default;
});