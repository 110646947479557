define("travis/services/external-links", ["exports", "travis/config/environment", "travis/utils/vcs"], function (_exports, _environment, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.vcsLinks = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // TODO:
  // The URLs below are used in `helpers/format-message.js` which needs
  // to be changed to use services.
  var vcsLinks = {
    commitUrl: function commitUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('commit', vcsType, params);
    },
    issueUrl: function issueUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('issue', vcsType, params);
    },
    profileUrl: function profileUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('profile', vcsType, params);
    },
    accessSettingsUrl: function accessSettingsUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('accessSettings', vcsType, params);
    }
  };
  _exports.vcsLinks = vcsLinks;

  var _default = Ember.Service.extend(_objectSpread({}, vcsLinks, {
    branchUrl: function branchUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('branch', vcsType, params);
    },
    fileUrl: function fileUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('file', vcsType, params);
    },
    repoUrl: function repoUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('repo', vcsType, params);
    },
    tagUrl: function tagUrl(vcsType, params) {
      return (0, _vcs.vcsUrl)('tag', vcsType, params);
    },
    email: function email(_email) {
      return "mailto:".concat(_email);
    },
    travisWebBranch: function travisWebBranch(branchName) {
      return "https://github.com/travis-ci/travis-web/tree/".concat(branchName);
    },
    billingUrl: function billingUrl(accountType, login) {
      var id = accountType === 'user' ? 'account' : "organizations/".concat(login);
      return "".concat(_environment.default.billingEndpoint, "/").concat(id, "/plan");
    },
    openSourceMigrationDocs: 'https://docs.travis-ci.com/user/open-source-on-travis-ci-com/#existing-open-source-repositories-on-travis-ciorg',
    betaMigrationDocs: 'https://docs.travis-ci.com/user/open-source-repository-migration/',
    platformLink: function platformLink(platform, rest) {
      return "https://travis-ci.".concat(platform, "/").concat(rest);
    },
    migratedToComLink: function migratedToComLink(slug) {
      return this.platformLink('com', slug);
    },
    migratedToComSettingsLink: function migratedToComSettingsLink(slug) {
      return this.platformLink('com', "".concat(slug, "/settings"));
    },
    orgBuildHistoryLink: function orgBuildHistoryLink(slug) {
      return this.platformLink('org', "".concat(slug, "/builds"));
    },
    comBuildHistoryLink: function comBuildHistoryLink(slug) {
      return this.platformLink('com', "".concat(slug, "/builds"));
    },
    communityTopicLink: function communityTopicLink(slug, id) {
      return "".concat(_environment.default.urls.community, "/t/").concat(slug, "/").concat(id);
    }
  }));

  _exports.default = _default;
});