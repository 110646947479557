define("travis/routes/signup", ["exports", "travis/mixins/tailwind-base"], function (_exports, _tailwindBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tailwindBase.default, {
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    needsAuth: false,
    beforeModel: function beforeModel() {
      if (this.get('auth.signedIn')) {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});