define("travis/routes/plans", ["exports", "travis/routes/basic", "travis/mixins/tailwind-base", "travis/config/environment"], function (_exports, _basic, _tailwindBase, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend(_tailwindBase.default, {
    needsAuth: false,
    features: Ember.inject.service(),
    auth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var pro = this.get('features.proVersion');
      var redirect = this.get('features.redirect');

      if (!this.auth.signedIn && _environment.default.environment !== 'test' && pro && redirect) {
        window.location.replace('https://www.travis-ci.com/pricing');
      }
    },
    redirect: function redirect() {
      if (!this.get('features.proVersion')) {
        return this.transitionTo('/');
      }
    }
  });

  _exports.default = _default;
});