define("travis/components/forms/form-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'placeholder',
    classNames: ['travis-form__field-placeholder', 'travis-form__field-component'],
    attributeBindings: [],
    onChange: function onChange() {},
    onFocus: function onFocus() {},
    onBlur: function onBlur() {},
    onInit: function onInit() {},
    onKeyUp: function onKeyUp() {},
    focusIn: function focusIn() {
      this.onFocus();
    },
    focusOut: function focusOut() {
      this.onBlur();
    },
    change: function change(_ref) {
      var target = _ref.target;
      this.onChange && this.onChange(target.value);
    },
    keyUp: function keyUp(_ref2) {
      var target = _ref2.target;
      this.onKeyUp && this.onKeyUp(target.value);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onInit(this.elementId);
    }
  });

  _exports.default = _default;
});