define("travis/routes/first-sync", ["exports", "travis/config/environment", "travis/routes/simple-layout"], function (_exports, _environment, _simpleLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleLayout.default.extend({
    storage: Ember.inject.service(),
    accounts: Ember.inject.service(),
    features: Ember.inject.service(),
    user: Ember.computed.alias('accounts.user'),
    activate: function activate() {
      var controller = this.controllerFor('firstSync');
      controller.addObserver('isSyncing', this, 'isSyncingDidChange');
      this.isSyncingDidChange();
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor('firstSync');
      return controller.removeObserver('controller.isSyncing', this, 'isSyncingDidChange');
    },
    getTransition: function getTransition() {
      if (this.get('features.enterpriseVersion')) return 'account';
      if (this.user.vcsType == 'AssemblaUser') return 'account';
      if (this.user.collaborator || this.user.hasV2Subscription || this.user.subscription || this.user.accountSubscriptions.length > 0 || this.user.accountv2Subscriptions.length > 0) return 'account';
      if (this.storage.wizardStep < 2 && !this.user.collaborator) return 'account_activation';
      if (this.storage.wizardStep >= 2 && this.storage.wizardStep <= 3) return 'account/repositories';
      return 'account';
    },
    isSyncingDidChange: function isSyncingDidChange() {
      var _this = this;

      var controller = this.controllerFor('firstSync');

      if (!controller.isSyncing) {
        if (this.get('features.enterpriseVersion')) {
          this.transitionTo(this.getTransition());
          return;
        }

        this.accounts.fetchSubscriptions.perform().then(function () {
          return _this.accounts.fetchV2Subscriptions.perform();
        }).then(function () {
          Ember.run.later(function () {
            return _this.transitionTo(_this.getTransition());
          }, _environment.default.timing.syncingPageRedirectionTime);
        });
      }
    }
  });

  _exports.default = _default;
});