define("travis/services/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINE_DELIMITER = '\n';
  var DATA_DELIMITER = ';';

  var _default = Ember.Service.extend({
    makeContent: function makeContent(header, data) {
      var output = header.join(DATA_DELIMITER) + LINE_DELIMITER;
      data.forEach(function (line) {
        output += line.join(DATA_DELIMITER) + LINE_DELIMITER;
      });
      return output;
    },
    asCSV: function asCSV(fileName, header, data) {
      var content = this.makeContent(header, data);
      var _window = window,
          document = _window.document,
          URL = _window.URL;
      var anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL.createObjectURL(new Blob([content], {
        type: 'text/csv'
      }));
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    },
    asTxt: function asTxt(fileName, content) {
      var _window2 = window,
          document = _window2.document,
          URL = _window2.URL;
      var anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = URL.createObjectURL(new Blob([content], {
        type: 'text/txt'
      }));
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    }
  });

  _exports.default = _default;
});