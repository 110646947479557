define("travis/templates/components/migration-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03wrvnIW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"notice-banner--yellow align-center\"],[8],[0,\"\\n    Since June 15th, 2021, the building on \"],[7,\"a\",true],[10,\"href\",\"http://www.travis-ci.org\"],[8],[0,\"travis-ci.org\"],[9],[0,\" is ceased. Please use \"],[7,\"a\",true],[10,\"href\",\"http://www.travis-ci.com\"],[8],[0,\"travis-ci.com\"],[9],[0,\" from now on.\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/migration-banner.hbs"
    }
  });

  _exports.default = _default;
});