define("travis/components/billing/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashes: Ember.inject.service(),
    account: null,
    newSubscriptionProcess: false,
    subscription: Ember.computed.reads('account.subscription'),
    hasExpiredStripeSubscription: Ember.computed.bool('account.expiredStripeSubscription'),
    v2subscription: Ember.computed.reads('account.v2subscription'),
    isV2SubscriptionEmpty: Ember.computed.empty('account.v2subscription'),
    hasV2Subscription: Ember.computed.not('isV2SubscriptionEmpty'),
    isProcessCompleted: Ember.computed(function () {
      return this.hasV2Subscription;
    }),
    newSubscription: Ember.computed(function () {
      var plan = this.store.createRecord('v2-plan-config');
      var billingInfo = this.store.createRecord('v2-billing-info');
      var creditCardInfo = this.store.createRecord('v2-credit-card-info');
      billingInfo.setProperties({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
        billingEmail: ''
      });
      creditCardInfo.setProperties({
        token: '',
        lastDigits: ''
      });
      return this.store.createRecord('v2-subscription', {
        billingInfo: billingInfo,
        plan: plan,
        creditCardInfo: creditCardInfo
      });
    }),
    didRender: function didRender() {
      var date = new Date();

      if (this.v2subscription && this.v2subscription.scheduledPlanName) {
        this.flashes.custom('flashes/scheduled-plan-change', {
          scheduledPlan: this.v2subscription.scheduledPlan,
          date: new Date(date.getFullYear(), date.getMonth() + 1, 1)
        }, 'warning');
      }
    }
  });

  _exports.default = _default;
});