define("travis/templates/components/billing/oss-credit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeB/xaJ7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"oss-toggle\"],[8],[0,\"\\n  \"],[5,\"travis-switch\",[],[[\"@active\",\"@disabled\",\"@onToggle\"],[[23,0,[\"consumeOSSCredits\"]],[23,0,[\"toggleOSSCredits\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"toggleOSSCredits\"]]],null]]]],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"oss-name\"],[8],[0,\"\\n    Consume paid credits for OSS\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"oss-description\"],[8],[0,\"\\n    You can use paid credits both for build jobs run over public and private repositories. \\n    You can use ‘OSS only’ credits only for build jobs run over public repositories. \\n    If the ‘Consume paid credits for OSS’ is set to OFF, paid credits will not be used for build\\n    jobs run over public repositories. ‘OSS only credits’ are available and renewed as per terms of selected plan.\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/oss-credit-toggle.hbs"
    }
  });

  _exports.default = _default;
});