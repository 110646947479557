define("travis/routes/scan-results", ["exports", "travis/routes/basic", "travis/utils/dynamic-query"], function (_exports, _basic, _dynamicQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAGE_CHANGED = _dynamicQuery.EVENTS.PAGE_CHANGED;

  var _default = _basic.default.extend({
    tabStates: Ember.inject.service(),
    auth: Ember.inject.service(),
    needsAuth: true,
    page: 1,
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var page = _ref.page;
      this.setProperties({
        page: page
      });
      return this.modelFor('repo');
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (model && !model.error) {
        var scanResults = model.scanResults;
        scanResults.switchToPage(this.page);
        scanResults.on(PAGE_CHANGED, function (page) {
          var queryParams = {
            page: page
          };

          _this.transitionTo({
            queryParams: queryParams
          });
        });
        return scanResults.load();
      }
    },
    activate: function activate() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      if (this.get('auth.signedIn')) {
        this.set('tabStates.sidebarTab', 'owned');
        this.set('tabStates.mainTab', 'scan_results');
      }
    },
    titleToken: function titleToken() {
      return 'Scan Results';
    },
    beforeModel: function beforeModel() {
      var repo = this.modelFor('repo');

      if (repo && !repo.repoOwnerAllowance) {
        repo.fetchRepoOwnerAllowance.perform();
      }
    }
  });

  _exports.default = _default;
});