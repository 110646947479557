define("travis/utils/credits_calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vmSizes = _exports.os = void 0;
  var os = [{
    value: 'linux',
    name: 'Linux'
  }, {
    value: 'osx',
    name: 'Mac OS X'
  }, {
    value: 'windows',
    name: 'Windows'
  }];
  _exports.os = os;
  var vmSizes = [{
    value: 'standard-2',
    shortName: 'M',
    name: 'M - 2 vCPU + 8 GiB RAM'
  }, {
    value: 'large',
    shortName: 'L',
    name: 'L - 4 vCPU + 16 GiB RAM'
  }, {
    value: 'x-large',
    shortName: 'XL',
    name: 'XL - 8 vCPU + 32 GiB RAM'
  }, {
    value: '2x-large',
    shortName: 'XXL',
    name: 'XXL - 16 vCPU + 64 GiB RAM'
  }];
  _exports.vmSizes = vmSizes;
});