define("travis/routes/plans/index", ["exports", "travis/routes/basic", "travis/config/environment"], function (_exports, _basic, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.auth.signedIn) {
        this.router.transitionTo('account.billing');
      }
    },
    model: function model() {
      this.store.pushPayload('plan', {
        '@type': 'plans',
        plans: _environment.default.plans
      });
      return {
        plans: this.store.peekAll('plan')
      };
    }
  });

  _exports.default = _default;
});