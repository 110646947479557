define("travis/components/no-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    accountRoute: Ember.computed('model', function () {
      return this.model === null || !this.model.vcsType.includes('Organization') ? 'account.billing' : 'organization.billing';
    })
  });

  _exports.default = _default;
});