define("travis/adapters/allowance", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    store: Ember.inject.service(),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var prefix = this.urlPrefix();
      var provider;
      var login;

      if (query) {
        provider = query.provider;
        login = query.login;
        delete query.login;
        delete query.provider;
      } else {
        var owner = this.store.peekRecord('user', id) || this.store.peekRecord('organization', id);
        provider = owner.provider;
        login = owner.login;
      }

      var providerPrefix = provider ? "".concat(provider, "/") : '';
      return "".concat(prefix, "/owner/").concat(providerPrefix).concat(login, "/allowance");
    }
  });

  _exports.default = _default;
});