define("travis/routes/build", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    tabStates: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return "Build #".concat(model.get('number'));
    },
    serialize: function serialize(model) {
      var id = model.get ? model.get('id') : model;
      return {
        build_id: id
      };
    },
    setupController: function setupController(controller, model) {
      if (model && !model.get) {
        model = this.store.recordForId('build', model);
        this.store.find('build', model);
      }

      var repo = this.controllerFor('repo');
      controller.set('build', model);
      return repo.activate('build');
    },
    activate: function activate() {
      this.set('tabStates.mainTab', 'build');
    },
    model: function model(params) {
      return this.store.findRecord('build', params.build_id);
    },
    afterModel: function afterModel(model) {
      var slug = this.modelFor('repo').get('slug');
      this.ensureBuildOwnership(model, slug);
      return model.get('request').then(function (request) {
        return request && request.fetchMessages.perform();
      });
    },
    beforeModel: function beforeModel() {
      var repo = this.modelFor('repo');

      if (repo && !repo.repoOwnerAllowance) {
        repo.fetchRepoOwnerAllowance.perform();
      }
    },
    ensureBuildOwnership: function ensureBuildOwnership(build, urlSlug) {
      var buildRepoSlug = build.get('repo.slug');

      if (buildRepoSlug !== urlSlug) {
        throw new Error('invalidBuildId');
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controllerFor('job').set('job', null);
      return this.controllerFor('build').set('build', null);
    }
  });

  _exports.default = _default;
});