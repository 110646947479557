define("travis/models/job", ["exports", "@ember-data/model", "travis/models/log", "travis/mixins/duration-calculations", "travis/mixins/duration-attributes", "travis/utils/promise-object"], function (_exports, _model, _log, _durationCalculations, _durationAttributes, _promiseObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OSX_VERSIONS = void 0;

  /* global Travis */
  var OSX_VERSIONS = {
    'xcode11.3': '10.14',
    'xcode11.2': '10.14',
    'xcode11.1': '10.14',
    'xcode11': '10.14',
    'xcode10.3': '10.14.4',
    'xcode10.2': '10.14',
    'xcode10.1': '10.13',
    'xcode10': '10.13',
    'xcode9.4': '10.13',
    'xcode9.3': '10.13',
    'xcode9.2': '10.12',
    'xcode9.1': '10.12',
    'xcode9': '10.12',
    'xcode8.3': '10.12',
    'xcode8': '10.11',
    'xcode7.3': '10.11',
    'xcode6.4': '10.10'
  };
  _exports.OSX_VERSIONS = OSX_VERSIONS;

  var _default = _model.default.extend(_durationCalculations.default, _durationAttributes.default, {
    api: Ember.inject.service(),
    jobConfigFetcher: Ember.inject.service(),
    features: Ember.inject.service(),
    logId: (0, _model.attr)(),
    queue: (0, _model.attr)(),
    state: (0, _model.attr)(),
    number: (0, _model.attr)(),
    jobIdNumber: (0, _model.attr)(),
    allowFailure: (0, _model.attr)('boolean'),
    tags: (0, _model.attr)(),
    repositoryPrivate: (0, _model.attr)(),
    repositorySlug: (0, _model.attr)(),
    updatedAt: (0, _model.attr)('date'),
    _config: (0, _model.attr)(),
    repo: (0, _model.belongsTo)('repo'),
    build: (0, _model.belongsTo)('build', {
      async: true
    }),
    commit: (0, _model.belongsTo)('commit', {
      async: true
    }),
    stage: (0, _model.belongsTo)('stage', {
      async: true
    }),
    isPullRequest: Ember.computed.alias('build.isPullRequest'),
    pullRequestNumber: Ember.computed.alias('build.pullRequestNumber'),
    pullRequestTitle: Ember.computed.alias('build.pullRequestTitle'),
    branch: Ember.computed.alias('build.branch'),
    branchName: Ember.computed.alias('build.branchName'),
    isTag: Ember.computed.alias('build.isTag'),
    tag: Ember.computed.alias('build.tag'),
    eventType: Ember.computed.alias('build.eventType'),
    restartedBy: (0, _model.attr)(),
    jobNumber: Ember.computed('number', 'jobIdNumber', function () {
      return this.jobIdNumber ? this.jobIdNumber : this.number;
    }),
    // TODO: DO NOT SET OTHER PROPERTIES WITHIN A COMPUTED PROPERTY!
    log: Ember.computed(function () {
      this.set('isLogAccessed', true);
      return _log.default.create({
        job: this,
        api: this.api,
        container: Ember.getOwner(this)
      });
    }),
    config: Ember.computed(function () {
      return (0, _promiseObject.default)(this.jobConfigFetcher.fetch(this));
    }),
    isConfigLoaded: Ember.computed.reads('config.isFulfilled'),
    os: Ember.computed('config.content.os', function () {
      var os = this.get('config.content.os');

      if (os === 'linux' || os === 'linux-ppc64le') {
        return 'linux';
      } else if (os === 'freebsd') {
        return 'freebsd';
      } else if (os === 'osx') {
        return 'osx';
      } else if (os === 'windows') {
        return 'windows';
      } else {
        return 'unknown';
      }
    }),
    dist: Ember.computed.reads('config.content.dist'),
    osxImage: Ember.computed.reads('config.content.osx_image'),
    osVersion: Ember.computed('os', 'dist', 'osxImage', function () {
      var os = this.os,
          dist = this.dist,
          osxImage = this.osxImage;

      if (os === 'osx') {
        return OSX_VERSIONS[osxImage];
      } else {
        return dist;
      }
    }),
    getCurrentState: function getCurrentState() {
      return this.get('currentState.stateName');
    },
    isFinished: Ember.computed('state', function () {
      var state = this.state;
      var finishedStates = ['passed', 'failed', 'errored', 'canceled'];
      return finishedStates.includes(state);
    }),
    isCreated: Ember.computed.equal('state', 'created'),
    isQueued: Ember.computed.equal('state', 'queued'),
    isReceived: Ember.computed.equal('state', 'received'),
    toBeQueued: Ember.computed('state', function () {
      var state = this.state;
      var queuedState = 'created';
      return Ember.isEqual(state, queuedState);
    }),
    toBeStarted: Ember.computed('state', function () {
      var state = this.state;
      var waitingStates = ['queued', 'received'];
      return waitingStates.includes(state);
    }),
    notStarted: Ember.computed('state', function () {
      var state = this.state;
      var waitingStates = ['created', 'queued', 'received'];
      return waitingStates.includes(state);
    }),
    clearLog: function clearLog() {
      if (this.isLogAccessed) {
        return this.log.clear();
      }
    },
    canCancel: Ember.computed('isFinished', 'state', function () {
      var isFinished = this.isFinished;
      var state = this.state;
      return !isFinished && !!state;
    }),
    canRestart: Ember.computed('isFinished', function () {
      var isFinished = this.isFinished;
      return isFinished;
    }),
    canDebug: Ember.computed.and('isFinished', 'repo.private'),
    cancel: function cancel() {
      var url = "/job/".concat(this.id, "/cancel");
      return this.api.post(url);
    },
    removeLog: function removeLog() {
      var _this = this;

      var url = "/job/".concat(this.id, "/log");
      return this.api.delete(url).then(function () {
        return _this.reloadLog();
      });
    },
    reloadLog: function reloadLog() {
      this.clearLog();
      return this.get('log.fetchTask').perform();
    },
    restart: function restart() {
      var url = "/job/".concat(this.id, "/restart");
      return this.api.post(url);
    },
    debug: function debug() {
      var url = "/job/".concat(this.id, "/debug");
      return this.api.post(url, {
        data: {
          quiet: true
        }
      });
    },
    appendLog: function appendLog(part) {
      return this.log.append(part);
    },
    subscribe: function subscribe() {
      var _this2 = this;

      if (this.subscribed) {
        return;
      }

      this.set('subscribed', true);
      return this.repo.then(function (repo) {
        return Travis.pusher.subscribe(_this2.channelName);
      });
    },
    channelName: Ember.computed('repo.private', 'id', 'features.enterpriseVersion', 'features.proVersion', function () {
      var isRepoPrivate = this.get('repo.private');
      var id = this.id;
      var enterprise = this.get('features.enterpriseVersion');
      var pro = this.get('features.proVersion'); // Currently always using private channels on Enterprise

      var usePrivateChannel = enterprise || isRepoPrivate || pro;
      var prefix = usePrivateChannel ? 'private-job' : 'job';
      return "".concat(prefix, "-").concat(id);
    }),
    unsubscribe: function unsubscribe() {
      if (!this.subscribed) {
        return;
      }

      this.set('subscribed', false);

      if (Travis.pusher) {
        var channel = "job-".concat(this.id);
        return Travis.pusher.unsubscribe(channel);
      }
    },
    onStateChange: Ember.observer('state', function () {
      if (this.state === 'finished' && Travis.pusher) {
        return this.unsubscribe();
      }
    }),
    canRemoveLog: Ember.computed('log.removed', function () {
      var removed = !!this.log.removed;
      return !removed;
    }),
    slug: Ember.computed('repo.slug', 'number', function () {
      var slug = this.get('repo.slug');
      var number = this.number;
      return "".concat(slug, " #").concat(number);
    }),
    didLoad: function didLoad() {
      if (this.number) this.set('jobIdNumber', this.number);
    }
  });

  _exports.default = _default;
});