define("travis/routes/account/plan_usage", ["exports", "travis/routes/basic", "travis/mixins/route/account/plan_usage"], function (_exports, _basic, _plan_usage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend(_plan_usage.default, {
    model: function model() {
      return Ember.RSVP.hash({
        account: this.modelFor('account')
      });
    }
  });

  _exports.default = _default;
});