define("travis/components/billing/credits-calculator", ["exports", "travis/utils/credits_calculator"], function (_exports, _credits_calculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    os: _credits_calculator.os,
    vmSizes: _credits_calculator.vmSizes,
    users: '',
    plans: [],
    builds: [{
      os: {},
      vmSize: {},
      minutes: ''
    }],
    configurations: [],
    form: null,
    selectPlan: null,
    bestPlan: Ember.computed('totalCredits', 'plans.[]', function () {
      var _this = this;

      return this.get('plans').find(function (item) {
        return item.annual === _this.get('isAnnual') && item.get('privateCredits') > _this.get('totalCredits');
      });
    }),
    totalPrice: Ember.computed('configurations.[]', function () {
      var sum = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('configurations')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var configuration = _step.value;
          sum += configuration.price;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return sum;
    }),
    totalCredits: Ember.computed('configurations.[]', function () {
      var sum = 0;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.get('configurations')[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var configuration = _step2.value;
          sum += configuration.credits;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return sum;
    }),
    calculate: function calculate() {
      var _this2 = this;

      var data = {
        users: this.users,
        executions: []
      };
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.get('builds')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var build = _step3.value;

          if (build.os.value !== undefined && parseInt(build.minutes) > 0) {
            var execution = {
              os: build.os.value,
              minutes: build.minutes
            };

            if (build.vmSize && build.vmSize.value) {
              execution.instance_size = build.vmSize.value;
            }

            data.executions.push(execution);
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      if (data.users.length === 0 || data.executions.length === 0) {
        return;
      }

      this.api.post('/credits_calculator', {
        data: data
      }).then(function (result) {
        _this2.get('configurations').clear();

        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = result.credits_results[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var creditResult = _step4.value;
            var config = {
              credits: creditResult.credits,
              price: creditResult.price
            };

            if (creditResult.users !== null) {
              config.name = "".concat(creditResult.users, " Users");
            } else {
              var selectedOs = '';
              var _iteratorNormalCompletion5 = true;
              var _didIteratorError5 = false;
              var _iteratorError5 = undefined;

              try {
                for (var _iterator5 = _credits_calculator.os[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
                  var system = _step5.value;

                  if (system.value === creditResult.os) {
                    selectedOs = system.name;
                    break;
                  }
                }
              } catch (err) {
                _didIteratorError5 = true;
                _iteratorError5 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
                    _iterator5.return();
                  }
                } finally {
                  if (_didIteratorError5) {
                    throw _iteratorError5;
                  }
                }
              }

              var selectedVmSize = '';
              var _iteratorNormalCompletion6 = true;
              var _didIteratorError6 = false;
              var _iteratorError6 = undefined;

              try {
                for (var _iterator6 = _credits_calculator.vmSizes[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                  var size = _step6.value;

                  if (size.value === creditResult.instance_size) {
                    selectedVmSize = size.shortName;
                    break;
                  }
                }
              } catch (err) {
                _didIteratorError6 = true;
                _iteratorError6 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                    _iterator6.return();
                  }
                } finally {
                  if (_didIteratorError6) {
                    throw _iteratorError6;
                  }
                }
              }

              config.name = "".concat(creditResult.minutes, " Mins, ").concat(selectedOs, " Builds");

              if (creditResult.os !== 'osx') {
                config.name += ", ".concat(selectedVmSize, " VM");
              }
            }

            _this2.get('configurations').pushObject(config);
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }
      });
    },
    loadDefaultConfig: function loadDefaultConfig() {
      var _this3 = this;

      this.api.get('/credits_calculator').then(function (result) {
        var selectedOs = {};
        var _iteratorNormalCompletion7 = true;
        var _didIteratorError7 = false;
        var _iteratorError7 = undefined;

        try {
          for (var _iterator7 = _credits_calculator.os[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
            var system = _step7.value;

            if (system.value === result.os) {
              selectedOs = {
                name: system.name,
                value: system.value
              };
              break;
            }
          }
        } catch (err) {
          _didIteratorError7 = true;
          _iteratorError7 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
              _iterator7.return();
            }
          } finally {
            if (_didIteratorError7) {
              throw _iteratorError7;
            }
          }
        }

        var selectedVmSize = {};
        var _iteratorNormalCompletion8 = true;
        var _didIteratorError8 = false;
        var _iteratorError8 = undefined;

        try {
          for (var _iterator8 = _credits_calculator.vmSizes[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
            var size = _step8.value;

            if (size.value === result.instance_size) {
              selectedVmSize = {
                name: size.name,
                value: size.value
              };
              break;
            }
          }
        } catch (err) {
          _didIteratorError8 = true;
          _iteratorError8 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion8 && _iterator8.return != null) {
              _iterator8.return();
            }
          } finally {
            if (_didIteratorError8) {
              throw _iteratorError8;
            }
          }
        }

        _this3.get('builds').clear();

        _this3.get('builds').pushObject({
          os: selectedOs,
          vmSize: selectedVmSize,
          minutes: result.minutes
        });

        _this3.set('users', result.users);

        _this3.calculate();
      });
    },
    addBuild: function addBuild() {
      this.get('builds').pushObject({
        os: {},
        vmSize: {},
        minutes: ''
      });
    },
    close: function close() {
      this.hideCalculator();
      this.get('builds').clear();
      this.addBuild();
      this.set('users', '');
      this.get('configurations').clear();
    },
    actions: {
      updateAttribute: function updateAttribute(buildIndex, attributeName, attribute) {
        this.set("builds.".concat(buildIndex, ".").concat(attributeName), attribute);
        this.calculate();
      },
      setUsers: function setUsers(value) {
        this.set('users', value);
        this.calculate();
      },
      addBuild: function addBuild() {
        this.addBuild();
      },
      loadDefaultConfig: function loadDefaultConfig() {
        this.loadDefaultConfig();
      },
      selectPlan: function selectPlan() {
        this.set('selectedPlan', this.get('bestPlan'));
        this.close();
        this.selectPlan(this.form);
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});