define("travis/components/repo-show-tabs", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    features: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['travistab-nav'],
    config: _environment.default,
    tab: Ember.computed.alias('tabStates.mainTab'),
    scansEnabled: Ember.computed.reads('features.logScanner'),
    classCurrent: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'current') {
        return 'active';
      }
    }),
    classBuilds: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'builds') {
        return 'active';
      }
    }),
    classPullRequests: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'pull_requests') {
        return 'active';
      }
    }),
    classScanResults: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'scan_results') {
        return 'active';
      }
    }),
    classScanResult: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'scan_result') {
        return 'active';
      }
    }),
    classBranches: Ember.computed('tab', function () {
      var tab = this.tab;

      if (tab === 'branches') {
        return 'active';
      }
    }),
    classBuild: Ember.computed('tab', function () {
      var tab = this.tab;
      var classes;
      classes = [];

      if (tab === 'build') {
        classes.push('active');
      }

      if (tab === 'build' || tab === 'job') {
        classes.push('display-inline');
      }

      return classes.join(' ');
    }),
    classJob: Ember.computed('tab', function () {
      if (this.tab === 'job') {
        return 'active';
      }
    }),
    classRequests: Ember.computed('tab', function () {
      if (this.tab === 'requests') {
        return 'active';
      }
    }),
    classCaches: Ember.computed('tab', function () {
      if (this.tab === 'caches') {
        return 'active';
      }
    }),
    classSettings: Ember.computed('tab', function () {
      if (this.tab === 'settings') {
        return 'active';
      }
    }),
    classRequest: Ember.computed('tab', function () {
      if (this.tab === 'request') {
        return 'active';
      }
    })
  });

  _exports.default = _default;
});