define("travis/templates/account/payment_details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M3onSqgI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"billing\"],[8],[0,\"\\n  \"],[5,\"billing/payment-details-tab\",[],[[\"@account\"],[[23,0,[\"account\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/account/payment_details.hbs"
    }
  });

  _exports.default = _default;
});