define("travis/controllers/github-apps-installation", ["exports", "fetch", "travis/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var interval = _environment.default.intervals.githubAppsInstallationPolling;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    raven: Ember.inject.service(),
    localStorage: Ember.inject.service('storage'),
    storage: Ember.computed.reads('localStorage.auth'),
    queryParams: ['installation_id'],
    repetitions: 0,
    maxRepetitions: 10,
    startPolling: function startPolling() {
      var _this = this;

      var isSignup = false;

      if (!this.installation_id) {
        var data = this.storage.get('activeAccountInstallation');

        if (data) {
          this.installation_id = data;
          isSignup = true;
        }

        this.storage.set('activeAccountInstallation', null);
      } else {
        var _data = this.storage.get('activeAccountInstallation');

        if (_data) {
          isSignup = true;
          this.storage.set('activeAccountInstallation', null);
        }
      }

      this.initialDelayPromise().then(function () {
        return _this.fetchPromise().then(function () {
          _this.transitionToRoute(isSignup ? 'first_sync' : 'account');
        });
      });
    },
    initialDelayPromise: function initialDelayPromise() {
      return new Promise(function (resolve) {
        setTimeout(resolve.bind(null), interval);
      });
    },
    fetchPromise: function fetchPromise() {
      var _this2 = this;

      var headers = new _fetch.Headers({
        'Authorization': "token ".concat(this.get('auth.token')),
        'Travis-API-Version': '3'
      });
      var url = "".concat(_environment.default.apiEndpoint, "/installation/") + "".concat(this.installation_id, "?include=installation.owner");
      return (0, _fetch.fetch)(url, {
        headers: headers
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          var repetitions = _this2.repetitions;
          var maxRepetitions = _this2.maxRepetitions;

          if (repetitions < maxRepetitions) {
            _this2.set('repetitions', repetitions + 1);

            return new Ember.RSVP.Promise(function (resolve) {
              return Ember.run.later(function () {
                return resolve(_this2.fetchPromise());
              }, interval);
            });
          } else {
            var exception = new Error("Timed out looking for owner of installation ".concat(_this2.installation_id));

            _this2.raven.logException(exception, true);
          }
        }
      });
    }
  });

  _exports.default = _default;
});