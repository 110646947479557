define("travis/components/sync-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    storage: Ember.inject.service(),
    wizard: Ember.inject.service('wizard-state'),
    user: Ember.computed.reads('auth.currentUser'),
    classNames: ['sync-button'],
    wizardState: Ember.computed.reads('wizard.state'),
    wizardStep: null,
    isSyncDisabled: Ember.computed('wizardStep', function () {
      return this.wizardStep >= 1 && this.wizardStep <= 3;
    }),
    actions: {
      sync: function sync() {
        return this.user.sync(this.isOrganization);
      },
      updateState: function updateState() {
        this.isSyncDisabled();
      }
    }
  });

  _exports.default = _default;
});