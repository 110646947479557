define("travis/routes/signin", ["exports", "travis/mixins/tailwind-base"], function (_exports, _tailwindBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_tailwindBase.default, {
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    queryParams: {
      redirectUrl: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var redirectUrl = _ref.redirectUrl;

      if (redirectUrl) {
        this.auth.setProperties({
          redirectUrl: redirectUrl
        });
      }
    }
  });

  _exports.default = _default;
});