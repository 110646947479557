define("travis/components/billing/warning-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sameAddons: Ember.computed('subscription.addons.[]', 'selectedPlan.addonConfigs.[]', function () {
      var _this = this;

      if (!this.selectedPlan.addonConfigs || !this.subscription.addons) return false;
      return this.selectedPlan.addonConfigs.any(function (addonConfig) {
        return _this.subscription.addons.any(function (addon) {
          return addon.name === addonConfig.name && addon.type === addonConfig.type;
        });
      });
    })
  });

  _exports.default = _default;
});