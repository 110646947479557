define("travis/components/scan-result-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    repo: null,
    missingNotice: 'No log scans for this repository',
    init: function init() {
      this.set('scanResults', null);
      return this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(!!this.repo) && Ember.assert('RepoBuildList requires @repo', !!this.repo));
    },
    user: Ember.computed.alias('auth.currentUser'),
    userHasPushPermissionForRepo: Ember.computed('repo.id', 'user', 'user.pushPermissions.[]', function () {
      var repo = this.repo;
      var user = this.user;

      if (user && repo) {
        return user.hasPushAccessToRepo(repo);
      }
    })
  });

  _exports.default = _default;
});