define("travis/templates/components/flashes/pending-user-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qOns4Fep",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"flash ember-view\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"warning below-top-bar ember-view\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"flash-message\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n        We are unable to start your build at this time. There are charges pending on your account.\\n        Please review your plan details and follow the steps to resolution.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flashes/pending-user-licenses.hbs"
    }
  });

  _exports.default = _default;
});