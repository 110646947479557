define("travis/templates/components/billing/price-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dcF/yUdI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isNotGithubTrial\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-plan__price\"],[8],[0,\"\\n    \"],[7,\"h6\",true],[10,\"class\",\"selected-plan__price--total\"],[8],[0,\"\\n      Total:\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"subscription\",\"plan\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"subscription\",\"plan\",\"isFree\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"selected-plan__price--large\"],[8],[0,\"\\n          Free\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"selected-plan__price--large\"],[8],[0,\"\\n          \"],[1,[28,\"format-currency\",[[23,0,[\"subscription\",\"plan\",\"startingPrice\"]]],[[\"floor\"],[\"true\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/price-v2.hbs"
    }
  });

  _exports.default = _default;
});