define("travis/routes/application", ["exports", "url", "travis/routes/basic", "travis/config/environment", "travis/mixins/build-favicon", "ember-keyboard-shortcuts"], function (_exports, _url, _basic, _environment, _buildFavicon, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Travis */
  var _default = _basic.default.extend(_buildFavicon.default, {
    auth: Ember.inject.service(),
    features: Ember.inject.service(),
    featureFlags: Ember.inject.service(),
    flashes: Ember.inject.service(),
    repositories: Ember.inject.service(),
    storage: Ember.inject.service(),
    wizard: Ember.inject.service('wizard-state'),
    queryParams: {
      selectedPlanId: null
    },
    needsAuth: false,
    init: function init() {
      var _this = this;

      this.featureFlags;
      this.auth.afterSignOut(function () {
        _this.afterSignOut();
      });
      return this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel() {
      return this.auth.autoSignIn();
    },
    model: function model(_model, transition) {
      var _this2 = this;

      if (_model.selectedPlanId) {
        this.storage.selectedPlanId = _model.selectedPlanId;
      }

      if (this.auth.signedIn) {
        this.wizard.fetch.perform().then(function () {
          _this2.storage.wizardStep = _this2.wizard.state;
        });
        return this.get('featureFlags.fetchTask').perform();
      }
    },
    activate: function activate() {
      this.setupRepoSubscriptions();
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    deactivate: function deactivate() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    // We send pusher updates through user channels now and this means that if a
    // user is not a collaborator of a repo or a user is not signed in, we need to
    // use repo channels for updates for each repo. This method ensures that a
    // visitor is subscribed to all of the public repos in the store as long as
    // they're not a collaborator. It also sets up an observer to subscribe to any
    // new repo that enters the store.
    setupRepoSubscriptions: function setupRepoSubscriptions() {
      var _this3 = this;

      this.store.filter('repo', null, function (repo) {
        return !repo.get('private') && !repo.get('isCurrentUserACollaborator');
      }, ['private', 'isCurrentUserACollaborator']).then(function (repos) {
        repos.forEach(function (repo) {
          return _this3.subscribeToRepo(repo);
        });
        repos.addArrayObserver(_this3, {
          willChange: 'reposWillChange',
          didChange: 'reposDidChange'
        });
      });
    },
    reposWillChange: function reposWillChange(array, start, removedCount, addedCount) {
      var _this4 = this;

      var removedRepos = array.slice(start, start + removedCount);
      return removedRepos.forEach(function (repo) {
        return _this4.unsubscribeFromRepo(repo);
      });
    },
    reposDidChange: function reposDidChange(array, start, removedCount, addedCount) {
      var _this5 = this;

      var addedRepos = array.slice(start, start + addedCount);
      return addedRepos.forEach(function (repo) {
        return _this5.subscribeToRepo(repo);
      });
    },
    unsubscribeFromRepo: function unsubscribeFromRepo(repo) {
      if (this.pusher && repo) {
        this.pusher.unsubscribe("repo-".concat(repo.get('id')));
      }
    },
    subscribeToRepo: function subscribeToRepo(repo) {
      if (this.pusher) {
        this.pusher.subscribe("repo-".concat(repo.get('id')));
      }
    },
    title: function title(titleParts) {
      if (titleParts.length) {
        titleParts = titleParts.reverse();
        titleParts.push('Travis CI');
        return titleParts.join(' - ');
      } else {
        return _environment.default.defaultTitle;
      }
    },
    keyboardShortcuts: {
      'up': {
        action: 'disableTailing',
        preventDefault: false
      },
      'down': {
        action: 'disableTailing',
        preventDefault: false
      }
    },
    actions: {
      signIn: function signIn() {
        var runAfterSignIn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.auth.signIn();

        if (runAfterSignIn) {
          this.afterSignIn();
        }
      },
      signOut: function signOut() {
        this.auth.signOut();
      },
      disableTailing: function disableTailing() {
        Travis.tailing.stop();
      },
      redirectToGettingStarted: function redirectToGettingStarted() {// keep as a no-op as this bubbles from other routes
      },
      error: function error(_error) {
        if (_error === 'needs-auth') {
          var currentURL = new _url.default(window.location.href);
          var redirectUrl = currentURL.href;
          var queryParams = {
            redirectUrl: redirectUrl
          };
          return this.transitionTo('signin', {
            queryParams: queryParams
          });
        } else {
          return true;
        }
      }
    },
    afterSignIn: function afterSignIn() {
      this.flashes.clear();
      var transition = this.get('auth.afterSignInTransition');

      if (transition) {
        this.set('auth.afterSignInTransition', null);
        return transition.retry();
      } else {
        return this.transitionTo('index');
      }
    },
    afterSignOut: function afterSignOut() {
      try {
        this.featureFlags.reset();
        this.set('repositories.accessible', []);
        this.setDefault();

        if (this.get('features.enterpriseVersion')) {
          return this.transitionTo('signin');
        }

        return this.transitionTo('index');
      } catch (error) {}
    }
  });

  _exports.default = _default;
});