define("travis/routes/legacy-repo-url", ["exports", "travis/utils/vcs"], function (_exports, _vcs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    templateName: 'error404',
    beforeModel: function beforeModel(transition) {
      var _transition$to = transition.to,
          params = _transition$to.params,
          queryParams = _transition$to.queryParams;
      var owner = params.owner,
          repo = params.repo,
          method = params.method,
          id = params.id,
          view = params.view,
          provider = params.provider,
          serverType = params.serverType;
      var vcsConfig,
          routeName = 'provider',
          routeModels = [];

      if (provider) {
        vcsConfig = (0, _vcs.vcsConfigByUrlPrefix)(provider);
      } else {
        vcsConfig = (0, _vcs.vcsConfigByUrlPrefix)(owner);
      }

      var isLegacyUrl = Ember.isEmpty(vcsConfig);
      var serverTypes = ['git', 'svn', 'perforce'];
      var isServerTypeUrl = serverTypes.includes(serverType) || serverTypes.includes(id);

      if (isLegacyUrl) {
        provider = _vcs.defaultVcsConfig.urlPrefix;
      } else {
        // params include provider, so swap them accordingly
        if (!isServerTypeUrl) {
          var _ref = [owner, repo, method, id, view];
          provider = _ref[0];
          owner = _ref[1];
          repo = _ref[2];
          method = _ref[3];
          id = _ref[4];
        } else if (serverTypes.includes(id)) {
          var _ref2 = [owner, repo, method, id, view];
          provider = _ref2[0];
          owner = _ref2[1];
          repo = _ref2[2];
          serverType = _ref2[3];
          method = _ref2[4];
          id = _ref2[5];
          view = _ref2[6];
        }
      }

      var newQueryParams = _objectSpread({
        serverType: serverType
      }, queryParams);

      routeModels.push(provider);

      if (owner) {
        routeName = 'owner';
        routeModels.push(owner);
      }

      if (repo) {
        routeName = 'repo';
        routeModels.push(repo);
      }

      if (method) {
        routeName = method;
      }

      if (id) {
        routeName = method.singularize();
        routeModels.push(id);
      }

      if (view) {
        routeName = "".concat(routeName, ".").concat(view);
      }

      if (this._router.hasRoute(routeName)) {
        transition.abort();
        this.transitionTo.apply(this, [routeName].concat(routeModels, [{
          queryParams: newQueryParams
        }]));
      }
    }
  });

  _exports.default = _default;
});