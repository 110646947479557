define("travis/routes/profile", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SECTION_ROUTE_MAP = {
    preferences: 'settings',
    plan: 'billing'
  };

  var _default = _basic.default.extend({
    accounts: Ember.inject.service(),
    beforeModel: function beforeModel(_ref) {
      var targetName = _ref.targetName;

      var _ref2 = this.paramsFor('profile') || {},
          section = _ref2.section,
          login = _ref2.login;

      var isUserAccount = this.accounts.user.login === login;
      var root = isUserAccount ? 'account' : 'organization';
      var routeName = "".concat(root).concat(section ? ".".concat(SECTION_ROUTE_MAP[section] || section) : '');

      if (isUserAccount) {
        this.transitionTo(routeName);
      } else {
        this.transitionTo(routeName, login);
      }
    }
  });

  _exports.default = _default;
});