define("travis/models/plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    privateCredits: (0, _model.attr)('number'),
    publicCredits: (0, _model.attr)('number'),
    builds: (0, _model.attr)('number'),
    annual: (0, _model.attr)('boolean'),
    users: (0, _model.attr)('number'),
    price: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    currency: (0, _model.attr)('string'),
    isEnabled: (0, _model.attr)('boolean'),
    isDefault: (0, _model.attr)('boolean'),
    isAnnual: (0, _model.attr)('boolean'),
    isFree: Ember.computed.equal('price', 0)
  });

  _exports.default = _default;
});