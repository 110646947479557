define("travis/components/top-bar", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    features: Ember.inject.service(),
    flashes: Ember.inject.service(),
    router: Ember.inject.service(),
    storage: Ember.inject.service(),
    tagName: 'header',
    classNames: ['top'],
    classNameBindings: ['isWhite:top--white'],
    isWhite: false,
    landingPage: false,
    isNavigationOpen: false,
    isActivation: false,
    activeModel: null,
    model: Ember.computed.reads('activeModel'),
    user: Ember.computed.reads('auth.currentUser'),
    isUnconfirmed: Ember.computed('user.confirmedAt', function () {
      if (!this.user || this.storage.wizardStep > 0 && this.storage.wizardStep <= 1 || this.router.currentRouteName == 'first_sync' || this.router.currentRouteName == 'github_apps_installation') return false;
      return !this.user.confirmedAt;
    }),
    userName: Ember.computed('user.{login,name}', function () {
      var login = this.get('user.login');
      var name = this.get('user.name');
      return name || login;
    }),
    showCta: Ember.computed('auth.signedIn', 'landingPage', 'features.landingPageCta', function () {
      var signedIn = this.get('auth.signedIn');
      var landingPage = this.landingPage;
      var ctaEnabled = this.get('features.landingPageCta');
      return !signedIn && !landingPage && ctaEnabled;
    }),
    hasNoPlan: Ember.computed('model.allowance.subscriptionType', 'model.hasV2Subscription', 'model.subscription', function () {
      return !this.get('model.hasV2Subscription') && this.get('model.subscription') === undefined && this.get('model.allowance.subscriptionType') === 3;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (Ember.testing) {
        this._super.apply(this, arguments);

        return;
      }

      Ember.setProperties(this, {
        viewportSpy: true
      });

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this$element$clientH = _this.element.clientHeight,
            clientHeight = _this$element$clientH === void 0 ? 76 : _this$element$clientH;
        Ember.set(_this, 'viewportTolerance.top', clientHeight);
      });
    },
    didEnterViewport: function didEnterViewport() {
      this.flashes.set('topBarVisible', true);
    },
    didExitViewport: function didExitViewport() {
      this.flashes.set('topBarVisible', false);
    },
    actions: {
      toggleNavigation: function toggleNavigation() {
        this.toggleProperty('isNavigationOpen');
      }
    }
  });

  _exports.default = _default;
});