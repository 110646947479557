define("travis/templates/requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xMH4r5av",
    "block": "{\"symbols\":[\"request\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"requests\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"created_at:desc\",[23,0,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"requests-item\",[],[[\"@request\",\"@highlightedRequestId\"],[[23,1,[]],[23,0,[\"requestId\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[5,\"missing-notice\",[],[[\"@title\"],[\"No requests for this repository\"]],{\"statements\":[[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"page-notice\"],[8],[0,\"\\n        Want to start testing this project on Travis CI?\\n      \"],[9],[0,\"\\n      \"],[5,\"external-link-to\",[[12,\"href\",\"http://docs.travis-ci.com/user/getting-started/\"],[12,\"class\",\"button\"],[12,\"title\",\"Travis documentation on getting set up\"]],[[],[]],{\"statements\":[[0,\"\\n        Read the Docs on Getting Started\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/requests.hbs"
    }
  });

  _exports.default = _default;
});