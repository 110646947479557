define("travis/components/build-header", ["exports", "travis/utils/job-config-arch", "travis/utils/job-config-language"], function (_exports, _jobConfigArch, _jobConfigLanguage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var commitMessageLimit = 72;

  var _default = Ember.Component.extend({
    externalLinks: Ember.inject.service(),
    tagName: 'section',
    classNames: ['build-header'],
    classNameBindings: ['item.state'],
    attributeBindings: ['jobId:data-job-id'],
    jobId: Ember.computed('item.{build,id,jobs}', function () {
      var build = this.get('item.build');
      var id = this.get('item.id');
      var jobs = this.get('item.jobs');

      if (build) {
        return id;
      } else {
        var ids = [];
        jobs = jobs || [];
        jobs.forEach(function (item) {
          ids.push(item.id);
        });
        return ids.join(' ');
      }
    }),
    isJob: Ember.computed('item.build', function () {
      var build = this.get('item.build');

      if (build) {
        return true;
      }

      return false;
    }),
    build: Ember.computed('isJob', function () {
      var isJob = this.isJob;

      if (isJob) {
        return this.get('item.build');
      } else {
        return this.item;
      }
    }),
    job: Ember.computed('isJob', 'item', 'item.jobs.firstObject', function () {
      if (this.isJob) {
        return this.item;
      } else {
        return this.get('item.jobs.firstObject');
      }
    }),
    jobsConfig: Ember.computed.reads('job.config'),
    displayCompare: Ember.computed('item.eventType', function () {
      var eventType = this.get('item.eventType');
      return !['api', 'cron'].includes(eventType);
    }),
    commitUrl: Ember.computed('item.repo.{ownerName,vcsName,vcsType,slug}', 'commit.sha', function () {
      var owner = this.get('item.repo.ownerName');
      var repo = this.get('item.repo.vcsName');
      var vcsType = this.get('item.repo.vcsType');
      var vcsId = this.get('item.repo.vcsId');
      var commit = this.get('commit.sha');
      var slugOwner = this.get('item.repo.slug').split('/')[0];
      return this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    branchUrl: Ember.computed('item.repo.{ownerName,vcsName,vcsType,slug}', 'build.branchName', function () {
      var owner = this.get('item.repo.ownerName');
      var repo = this.get('item.repo.vcsName');
      var vcsType = this.get('item.repo.vcsType');
      var vcsId = this.get('item.repo.vcsId');
      var branch = this.get('build.branchName');
      var slugOwner = this.get('item.repo.slug').split('/')[0];
      return this.externalLinks.branchUrl(vcsType, {
        owner: owner,
        repo: repo,
        branch: branch,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    tagUrl: Ember.computed('item.repo.{ownerName,vcsName,vcsType,slug}', 'build.tag.name', function () {
      var owner = this.get('item.repo.ownerName');
      var repo = this.get('item.repo.vcsName');
      var vcsType = this.get('item.repo.vcsType');
      var vcsId = this.get('item.repo.vcsId');
      var tag = this.get('build.tag.name');
      var slugOwner = this.get('item.repo.slug').split('/')[0];
      return this.externalLinks.tagUrl(vcsType, {
        owner: owner,
        repo: repo,
        tag: tag,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    buildState: Ember.computed('item.jobs.firstObject.state', 'item.state', 'item.isMatrix', function () {
      var jobState = this.get('item.jobs.firstObject.state');
      var buildState = this.get('item.state');
      var isMatrix = this.get('item.isMatrix');

      if (isMatrix) {
        return buildState;
      } else {
        return jobState || buildState;
      }
    }),
    serverTypeIcon: Ember.computed.reads('item.repo.serverType'),
    serverType: Ember.computed('item.repo.serverType', function () {
      var serverType = this.get('item.repo.serverType');
      if (!serverType) return '';

      if (serverType === 'svn') {
        return 'SVN';
      } else {
        return serverType.capitalize();
      }
    }),
    languages: Ember.computed('jobsConfig.content', function () {
      var config = this.get('jobsConfig.content');
      return (0, _jobConfigLanguage.default)(config);
    }),
    name: Ember.computed('jobsConfig.content.name', function () {
      var name = this.get('jobsConfig.content.name');

      if (name) {
        return name;
      }
    }),
    globalEnv: Ember.computed.reads('build.request.config.env.global'),
    jobEnv: Ember.computed.reads('jobsConfig.content.env'),
    gemfile: Ember.computed.reads('jobsConfig.content.gemfile'),
    environment: Ember.computed('globalEnv', 'jobEnv', 'gemfile', function () {
      if (this.jobEnv) {
        var globalEnv = this.globalEnv || [];

        var join = function join(vars, pair) {
          return vars.concat([pair.join('=')]);
        };

        var vars = globalEnv.reduce(function (vars, obj) {
          return Object.entries(obj).reduce(join, vars);
        }, []);
        return vars.reduce(function (env, str) {
          return env.replace(str, '');
        }, this.jobEnv);
      } else if (this.gemfile) {
        return "Gemfile: ".concat(this.gemfile);
      }
    }),
    os: Ember.computed.reads('job.os'),
    osVersion: Ember.computed.reads('job.osVersion'),
    arch: Ember.computed('jobsConfig.content.arch', function () {
      var config = this.get('jobsConfig.content');
      return (0, _jobConfigArch.default)(config);
    }),
    osIcon: Ember.computed('os', function () {
      var os = this.os;

      if (os === 'linux') {
        return 'icon-linux';
      } else if (os === 'freebsd') {
        return 'icon-freebsd';
      } else if (os === 'osx') {
        return 'icon-mac';
      } else if (os === 'windows') {
        return 'icon-windows';
      } else {
        return 'help';
      }
    }),
    commitBodyClass: Ember.computed('item.commit.body', function () {
      var body = this.get('item.commit.body');
      return body.length > commitMessageLimit ? 'fade-commit-message' : '';
    }),
    isNotMatrix: Ember.computed.not('item.isMatrix'),
    envExpanded: false,
    actions: {
      closeEnv: function closeEnv() {
        this.set('envExpanded', false);
      },
      expandEnv: function expandEnv() {
        this.set('envExpanded', true);
      },
      toggleEnv: function toggleEnv() {
        this.set('envExpanded', !this.envExpanded);
      }
    }
  });

  _exports.default = _default;
});