define("travis/components/layouts/activation-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'activation-section',
    classNames: ['layout-activation-section'],
    classNameBindings: ['isHeader:layout-activation-section--header'],
    isHeader: false
  });

  _exports.default = _default;
});