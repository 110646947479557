define("travis/adapters/v2-plan-config", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var type = query.type,
          orgId = query.orgId;
      var isOrganization = type === 'organization';
      var isUser = type === 'user';
      (false && !(requestType === 'query') && Ember.assert('The plan config adapter only supports a query request type', requestType === 'query'));
      (false && !(isUser || isOrganization && !!orgId) && Ember.assert('Invalid request parameters for plan config adapter', isUser || isOrganization && !!orgId));
      var path = isOrganization ? "v2_plans_for/organization/".concat(orgId) : 'v2_plans_for/user';
      return "".concat(this.urlPrefix(), "/").concat(path);
    },
    // This overrides the parent implementation to ignore the query parameters
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      return this.ajax(url, 'GET');
    }
  });

  _exports.default = _default;
});