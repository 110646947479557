define("travis/components/billing/information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newSubscription: null,
    billingInfo: Ember.computed.reads('subscription.billingInfo'),
    actions: {
      updateEmails: function updateEmails(values) {
        this.billingInfo.set('billingEmail', values.join(','));
      }
    }
  });

  _exports.default = _default;
});