define("travis/templates/components/billing/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L3qdKYfA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[5,\"loading-indicator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"section\",true],[10,\"class\",\"billing\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,0,[\"isEducation\"]],[28,\"not\",[[23,0,[\"isEduProcessCompleted\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[5,\"billing/education\",[],[[\"@account\",\"@selectedPlan\",\"@subscription\",\"@newSubscription\",\"@isProcessCompleted\"],[[23,0,[\"account\"]],[23,0,[\"selectedPlan\"]],[23,0,[\"subscription\"]],[23,0,[\"newV2Subscription\"]],[23,0,[\"isEduProcessCompleted\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[23,0,[\"isManual\"]],[23,0,[\"isV2SubscriptionEmpty\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"billing-manual\",[],[[\"@subscription\",\"@account\",\"@selectedPlan\"],[[23,0,[\"subscription\"]],[23,0,[\"account\"]],[23,0,[\"selectedPlan\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isSubscription\"]]],null,{\"statements\":[[0,\"      \"],[5,\"billing/subscription\",[],[[\"@selectedPlan\",\"@showPlansSelector\",\"@showAddonsSelector\",\"@account\"],[[23,0,[\"selectedPlan\"]],[23,0,[\"showPlansSelector\"]],[23,0,[\"showAddonsSelector\"]],[23,0,[\"account\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"or\",[[23,0,[\"isTrial\"]],[28,\"not\",[[23,0,[\"isTrialProcessCompleted\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[5,\"billing/trial\",[],[[\"@account\",\"@selectedPlan\",\"@newSubscription\",\"@isProcessCompleted\"],[[23,0,[\"account\"]],[23,0,[\"selectedPlan\"]],[23,0,[\"newV2Subscription\"]],[23,0,[\"isTrialProcessCompleted\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/account.hbs"
    }
  });

  _exports.default = _default;
});