define("travis/templates/components/owner-sync-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7Vx4C8t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sync-button\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"owner\",\"isSyncing\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"button\"],[10,\"disabled\",\"disabled\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"lading-indicator--white\"],[8],[0,\"\\n          \"],[7,\"i\",true],[8],[9],[0,\"\\n          \"],[7,\"i\",true],[8],[9],[0,\"\\n          \"],[7,\"i\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        Syncing\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"owner\",\"roMode\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"button\"],[10,\"disabled\",\"disabled\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button\"],[3,\"action\",[[23,0,[]],[23,0,[\"sync\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"icon icon-sync\"],[8],[9],[0,\" Sync account\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"sync-last\"],[8],[0,\"\\n      last synced \"],[1,[28,\"format-time\",[[23,0,[\"owner\",\"syncedAt\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/owner-sync-button.hbs"
    }
  });

  _exports.default = _default;
});