define("travis/components/profile-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['profile', 'menu'],
    classNameBindings: ['auth.state', 'isMenuOpen:open'],
    auth: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    isMenuOpen: false,
    isActivation: false,
    user: Ember.computed.reads('auth.currentUser'),
    userName: Ember.computed.or('user.name', 'user.login'),

    get redirectUrl() {
      return window.location.href;
    },

    isSignInPage: Ember.computed.equal('router.currentRouteName', 'signin'),
    showSignInButton: Ember.computed.not('isSignInPage'),
    closeMenu: function closeMenu() {
      if (this.isMenuOpen) {
        this.set('isMenuOpen', false);
        this.disableAutoClose();
      }
    },
    openMenu: function openMenu() {
      var _this = this;

      if (!this.isMenuOpen) {
        this.set('isMenuOpen', true);
        Ember.run.next(function () {
          return _this.enableAutoClose();
        });
      }
    },
    enableAutoClose: function enableAutoClose() {
      var _this2 = this;

      this.clickHandler = function () {
        _this2.closeMenu();
      };

      document.addEventListener('click', this.clickHandler);
    },
    disableAutoClose: function disableAutoClose() {
      if (this.clickHandler) {
        document.removeEventListener('click', this.clickHandler);
        this.clickHandler = null;
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.closeMenu();
    },
    actions: {
      signIn: function signIn() {
        var redirectUrl = this.redirectUrl;
        this.router.transitionTo('signin', {
          queryParams: {
            redirectUrl: redirectUrl
          }
        });
      },
      signOut: function signOut() {
        return this.auth.signOut();
      },
      toggleMenu: function toggleMenu() {
        if (this.isMenuOpen) {
          this.closeMenu();
        } else {
          this.openMenu();
        }
      }
    }
  });

  _exports.default = _default;
});