define("travis/templates/components/sync-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBvScmk+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"user\",\"isSyncing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"button--transparent color-oxide-blue\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"loading-indicator label-align\"],[8],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n      \"],[7,\"i\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"user\",\"roMode\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"button--transparent\"],[10,\"disabled\",\"disabled\"],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"sync-icon\",\"icon--l\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",\"button--transparent\"],[12,\"disabled\",[28,\"if\",[[23,0,[\"isSyncDisabled\"]],true,false],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"sync\"],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\n    \"],[5,\"ember-popover\",[],[[\"@side\"],[\"top\"]],{\"statements\":[[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"nowrap\"],[8],[0,\"Last synced \"],[1,[28,\"format-time\",[[23,0,[\"user\",\"syncedAt\"]]],null],false],[0,\" \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"nowrap\"],[8],[0,\"Click the button to sync.\"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[[29,[[28,\"if\",[[23,0,[\"isSyncDisabled\"]],\"sync-icon-disabled\",\"sync-icon\"],null]]],\"icon--sync \"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sync-last-container\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"sync-last\"],[8],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/sync-button.hbs"
    }
  });

  _exports.default = _default;
});