define("travis/templates/account-activation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8iLlz0r9",
    "block": "{\"symbols\":[\"layout\"],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/activation\"]],{\"statements\":[[0,\"\\n   \"],[7,\"h1\",true],[10,\"class\",\"header-main text-header align-center\"],[8],[0,\" Enable access to CI/CD world \"],[9],[0,\"\\n  \"],[6,[23,1,[\"activation-section\"]],[],[[\"@isHeader\"],[false]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"subheading text-subheader align-left\"],[8],[0,\"\\n        We don't like paperwork but to keep Travis CI free from spam and\\n        cryptocurrency miners, we ask to verify your account with valid Credit Card.\\n        Until you do this, you will not be able to use all the functionality of Travis CI.\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n   \"],[6,[23,1,[\"activation-section\"]],[],[[\"@isHeader\"],[false]],{\"statements\":[[0,\" \\n    \"],[5,\"billing/first-plan\",[],[[\"@user\"],[[23,0,[\"user\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/account-activation.hbs"
    }
  });

  _exports.default = _default;
});