define("travis/utils/keys-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archConfigKeys = _exports.configKeys = _exports.languageConfigKeys = _exports.default = void 0;
  var configKeys, configKeysMap, languageConfigKeys, archConfigKeys;
  _exports.archConfigKeys = archConfigKeys;
  _exports.languageConfigKeys = languageConfigKeys;
  _exports.configKeys = configKeys;
  _exports.languageConfigKeys = languageConfigKeys = {
    android: 'Android',
    bash: 'Shell',
    c: 'C',
    clojure: 'Clojure',
    compiler: 'Compiler',
    cpp: 'C++',
    crystal: 'Crystal',
    csharp: 'C#',
    d: 'D',
    dart_task: 'Task',
    dart: 'Dart',
    elixir: 'Elixir',
    elm: 'Elm',
    erlang: 'Erlang',
    ghc: 'GHC',
    go: 'Go',
    haskell: 'Haskell',
    haxe: 'Haxe',
    java: 'Java',
    jdk: 'JDK',
    julia: 'Julia',
    lein: 'Lein',
    mono: 'Mono',
    matlab: 'MATLAB',
    nix: 'Nix',
    node_js: 'Node.js',
    objective_c: 'Objective-C',
    osx_image: 'Xcode',
    otp_release: 'OTP Release',
    perl: 'Perl',
    perl6: 'Perl6',
    php: 'PHP',
    python: 'Python',
    r: 'R',
    ruby: 'Ruby',
    rust: 'Rust',
    rvm: 'Ruby',
    scala: 'Scala',
    sh: 'Shell',
    shell: 'Shell',
    smalltalk_config: 'Config',
    smalltalk: 'Smalltalk',
    xcode_scheme: 'Xcode scheme'
  };
  _exports.configKeys = configKeys = {
    env: 'ENV',
    gemfile: 'Gemfile',
    xcode_sdk: 'Xcode SDK',
    xcode_scheme: 'Xcode Scheme',
    compiler: 'Compiler',
    os: 'OS'
  };
  _exports.archConfigKeys = archConfigKeys = {
    arm64: 'Arm64',
    amd64: 'AMD64'
  };
  configKeysMap = Ember.assign(configKeys, languageConfigKeys);
  var _default = configKeysMap;
  _exports.default = _default;
});