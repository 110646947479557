define("travis/templates/components/flashes/read-only-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vvNs6vGY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"flash ember-view\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"warning below-top-bar ember-view\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"flash-message\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n        \"],[1,[28,\"config-get\",[\"defaultTitle\"],null],false],[0,\" is in read-only mode. The historical data can be downloaded to external file storage.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flashes/read-only-mode.hbs"
    }
  });

  _exports.default = _default;
});