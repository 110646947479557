define("travis/components/scan-result-details", ["exports", "travis/utils/log", "travis/utils/log-folder"], function (_exports, _log, _logFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    externalLinks: Ember.inject.service(),
    auth: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      if (this.get('features.debugLogging')) {
        // eslint-disable-next-line
        console.log('log view: did insert');
      }

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'setupLog');
    },
    setupLog: function setupLog() {
      this.engine = _log.default.create();
      this.logFolder = new _logFolder.default(this.element.querySelector('.log-body-content'));
      this.engine.set(0, this.scanResult.formattedContent);
    },
    commitUrl: Ember.computed('repo.{ownerName,vcsName,vcsType,slug}', 'scanResult.commitSha', function () {
      var owner = this.get('repo.ownerName');
      var repo = this.get('repo.vcsName');
      var vcsType = this.get('repo.vcsType');
      var vcsId = this.get('repo.vcsId');
      var commit = this.get('scanResult.commitSha');
      var slugOwner = this.get('repo.slug').split('/')[0];
      return this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    branchUrl: Ember.computed('repo.{ownerName,vcsName,vcsType,slug}', 'scanResult.commitBranch', function () {
      var owner = this.get('repo.ownerName');
      var repo = this.get('repo.vcsName');
      var vcsType = this.get('repo.vcsType');
      var vcsId = this.get('repo.vcsId');
      var branch = this.get('scanResult.commitBranch');
      var slugOwner = this.get('repo.slug').split('/')[0];
      return this.externalLinks.branchUrl(vcsType, {
        owner: owner,
        repo: repo,
        branch: branch,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    user: Ember.computed.alias('auth.currentUser'),
    userHasPushPermissionForRepo: Ember.computed('repo.id', 'user', 'user.pushPermissions.[]', function () {
      var repo = this.repo;
      var user = this.user;

      if (user && repo) {
        return user.hasPushAccessToRepo(repo);
      }
    })
  });

  _exports.default = _default;
});