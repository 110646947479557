define("travis/templates/components/builds-backup-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lqqeeuPZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"two-line\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row-item build-info\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-download\",\"icon--m\"]]],[0,\"\\n      \"],[1,[23,0,[\"build\",\"file_name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row-item download\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"download-export\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"downloadExport\"],null]],[8],[0,\"download\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"two-line actions\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"row-item request\"],[8],[0,\"\\n    \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"icon-calendar\",\"icon\"]]],[0,\"\\n    \"],[7,\"time\",true],[10,\"class\",\"label-align\"],[11,\"datetime\",[23,0,[\"build\",\"created_at\"]]],[8],[0,\"\\n      \"],[1,[28,\"format-time\",[[23,0,[\"build\",\"created_at\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/builds-backup-item.hbs"
    }
  });

  _exports.default = _default;
});