define("travis/adapters/invoice", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var type = query.type,
          subscriptionId = query.subscriptionId;
      var route = type === 2 ? 'v2_subscription' : 'subscription';

      if (requestType === 'query' && subscriptionId) {
        var prefix = this.urlPrefix();
        return "".concat(prefix, "/").concat(route, "/").concat(subscriptionId, "/invoices");
      } else {
        throw new Error("The invoices adapter only supports a\n        query request type with a query including subscription_id");
      }
    },
    // This overrides the parent implementation to ignore the query parameters
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      return this.ajax(url, 'GET');
    }
  });

  _exports.default = _default;
});