define("travis/templates/components/forms/form-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HChWaSjV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"travis-form__field-radio-wrapper\"],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"checked\",[23,0,[\"checked\"]]],[11,\"disabled\",[23,0,[\"disabled\"]]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"toggle\"],null]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"focus\"],null]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"blur\"],null]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"radio-checked\",\"travis-form__field-radio-image--checked\"]]],[0,\"\\n  \"],[5,\"svg-image\",[],[[\"@name\",\"@class\"],[\"radio-unchecked\",\"travis-form__field-radio-image--unchecked\"]]],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/forms/form-radio.hbs"
    }
  });

  _exports.default = _default;
});