define("travis/models/allowance", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    subscriptionType: (0, _model.attr)('number'),
    publicRepos: (0, _model.attr)('boolean'),
    privateRepos: (0, _model.attr)('boolean'),
    userUsage: (0, _model.attr)('boolean'),
    pendingUserLicenses: (0, _model.attr)('boolean'),
    concurrencyLimit: (0, _model.attr)('number'),
    paymentChangesBlockCredit: (0, _model.attr)('boolean'),
    paymentChangesBlockCaptcha: (0, _model.attr)('boolean'),
    creditCardBlockDuration: (0, _model.attr)('number'),
    captchaBlockDuration: (0, _model.attr)('number'),
    owner: (0, _model.belongsTo)('owner')
  });

  _exports.default = _default;
});