define("travis/services/storage/auth", ["exports", "travis/services/storage"], function (_exports, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storage = getStorage();

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    token: Ember.computed({
      get: function get() {
        return storage.getItem('travis.token') || null;
      },
      set: function set(key, token) {
        (false && !(token === null) && Ember.assert('Token storage is read-only', token === null));
        storage.removeItem('travis.token');
        return null;
      }
    }),
    rssToken: Ember.computed({
      get: function get() {
        return storage.getItem('travis.rssToken') || null;
      },
      set: function set(key, token) {
        (false && !(token === null) && Ember.assert('RSS Token storage is read-only', token === null));
        storage.removeItem('travis.rssToken');
        return null;
      }
    }),
    user: Ember.computed({
      get: function get() {
        var data = (0, _storage.parseWithDefault)(storage.getItem('travis.user'), null);
        return data && data.user || data;
      },
      set: function set(key, user) {
        (false && !(user === null) && Ember.assert('User storage is read-only', user === null));
        storage.removeItem('travis.user');
        return null;
      }
    }),
    accounts: Ember.computed({
      get: function get() {
        var _this = this;

        var accountsData = storage.getItem('travis.auth.accounts');
        var accounts = (0, _storage.parseWithDefault)(accountsData, []).map(function (account) {
          return extractAccountRecord(_this.store, account);
        });
        accounts.addArrayObserver(this, {
          willChange: 'persistAccounts',
          didChange: 'persistAccounts'
        });
        return accounts;
      },
      set: function set(key, accounts) {
        this.persistAccounts(accounts);
        return accounts;
      }
    }).volatile(),
    persistAccounts: function persistAccounts(newValue) {
      var records = (newValue || []).map(function (record) {
        return serializeUserRecord(record);
      });
      storage.setItem('travis.auth.accounts', JSON.stringify(records));
    },
    activeAccountId: Ember.computed({
      get: function get() {
        return +storage.getItem('travis.auth.activeAccountId');
      },
      set: function set(key, id) {
        if (id === null) {
          storage.removeItem('travis.auth.activeAccountId');
          return null;
        } else {
          storage.setItem('travis.auth.activeAccountId', id);
          return id;
        }
      }
    }),
    activeAccountInstallation: Ember.computed({
      get: function get() {
        return +storage.getItem('travis.auth.activeAccountInstallation');
      },
      set: function set(key, id) {
        if (id === null) {
          storage.removeItem('travis.auth.activeAccountInstallation');
          return null;
        } else {
          storage.setItem('travis.auth.activeAccountInstallation', id);
          return id;
        }
      }
    }),
    activeAccount: Ember.computed({
      get: function get() {
        var accounts = this.accounts,
            activeAccountId = this.activeAccountId;
        return accounts.find(function (account) {
          return +account.id === activeAccountId;
        });
      },
      set: function set(key, account) {
        var id = account && account.id || null;
        this.set('activeAccountId', id);
        return account;
      }
    }),
    isBecome: Ember.computed(function () {
      return !!storage.getItem('travis.auth.become');
    }),
    setRegeneratedToken: function setRegeneratedToken(token) {
      storage.setItem('travis.token', token);
    },
    clearLoginData: function clearLoginData() {
      storage.removeItem('travis.token');
      storage.removeItem('travis.user');
      storage.removeItem('travis.auth.become');
    },
    clear: function clear() {
      this.clearLoginData();
      storage.removeItem('travis.auth.accounts');
      storage.removeItem('travis.auth.activeAccountId');
    }
  }); // HELPERS


  _exports.default = _default;

  function getStorage() {
    var localStorage = {};
    var sessionStorage = {};

    if (typeof window !== 'undefined') {
      localStorage = window.localStorage;
      sessionStorage = window.sessionStorage;
    } // primary storage for auth is the one in which auth data was updated last


    var sessionStorageUpdatedAt = +sessionStorage.getItem('travis.auth.updatedAt');
    var localStorageUpdatedAt = +localStorage.getItem('travis.auth.updatedAt');
    return sessionStorageUpdatedAt > localStorageUpdatedAt ? sessionStorage : localStorage;
  }

  function serializeUserRecord(record) {
    return record.serialize({
      includeId: true,
      forLocalStorage: true
    });
  }

  function extractAccountRecord(store, userData) {
    var record = store.peekRecord('user', userData.id);
    return record || store.push(store.normalize('user', userData));
  }
});