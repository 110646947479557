define("travis/templates/components/dialogs/cancel-subscription-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFi0yPGz",
    "block": "{\"symbols\":[\"@isOpen\",\"@onClose\"],\"statements\":[[5,\"modal\",[],[[\"@isVisible\",\"@onClose\",\"@closeButton\"],[[23,1,[]],[28,\"fn\",[[23,2,[]]],null],true]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex--v-center flex--center flex--col cancel-subscription-modal\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"We’re sorry to see you go\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"cancel-subscription-modal__message\"],[8],[0,\"\\n      Your cancellation request has been forwarded to Support.\"],[7,\"br\",true],[8],[9],[0,\"Our Support team will contact you soon.\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"full-width\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex flex--v-center flex--center\"],[8],[0,\"\\n          \"],[7,\"a\",false],[12,\"class\",\"button--red confirm-cancellation-button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[]]],null]]],[8],[0,\"\\n            OK\\n          \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/dialogs/cancel-subscription-modal.hbs"
    }
  });

  _exports.default = _default;
});