define("travis/templates/components/dialogs/plan-switch-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "swt4wW4S",
    "block": "{\"symbols\":[\"@onClose\"],\"statements\":[[5,\"modal\",[],[[\"@isVisible\",\"@closeOnClickOverlay\",\"@closeButton\",\"@onClose\"],[[23,0,[\"isOpen\"]],false,true,[28,\"fn\",[[23,1,[]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"migrate-beta-dialog\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[10,\"class\",\"h2--teal align-center\"],[8],[0,\"\\n      Warning!\\n    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Please confirm switching from credit-based plans to a concurrency-based plan. Your current credits will not be available anymore.\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-controls\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"button--green\"],[3,\"on\",[\"click\",[28,\"perform\",[[23,0,[\"updatePlan\"]]],null]]],[8],[0,\"\\n        Pay to complete order\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/dialogs/plan-switch-warning.hbs"
    }
  });

  _exports.default = _default;
});