define("travis/models/user", ["exports", "@ember-data/model", "travis/models/owner", "travis/config/environment"], function (_exports, _model, _owner, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Travis */
  var _default = _owner.default.extend({
    api: Ember.inject.service(),
    accounts: Ember.inject.service(),
    features: Ember.inject.service(),
    permissionsService: Ember.inject.service('permissions'),
    wizardStateService: Ember.inject.service('wizardState'),
    email: (0, _model.attr)('string'),
    emails: (0, _model.attr)(),
    // list of all known user emails
    token: (0, _model.attr)('string'),
    secureUserHash: (0, _model.attr)('string'),
    gravatarId: (0, _model.attr)('string'),
    firstLoggedInAt: (0, _model.attr)('date'),
    allowMigration: (0, _model.attr)('boolean'),
    recentlySignedUp: (0, _model.attr)('boolean'),
    channels: (0, _model.attr)(),
    authToken: (0, _model.attr)('string'),
    rssToken: (0, _model.attr)('string'),
    utmParams: (0, _model.attr)(),
    confirmedAt: (0, _model.attr)('date'),
    customKeys: (0, _model.attr)(),
    collaborator: (0, _model.attr)('boolean'),
    type: 'user',
    fullName: Ember.computed.or('name', 'login'),
    applyFilterRepos: false,
    gravatarUrl: Ember.computed('gravatarId', function () {
      return "https//www.gravatar.com/avatar/".concat(this.gravatarId, "?s=48&d=mm");
    }),
    init: function init() {
      this.schedulePoll();
      return this._super.apply(this, arguments);
    },
    permissions: Ember.computed.reads('permissionsService.all'),
    adminPermissions: Ember.computed.reads('permissionsService.admin'),
    pullPermissions: Ember.computed.reads('permissionsService.pull'),
    pushPermissions: Ember.computed.reads('permissionsService.push'),
    wizardState: Ember.computed.reads('wizardStateService.state'),
    hasAccessToRepo: function hasAccessToRepo(repo) {
      var id = repo.get ? repo.get('id') : repo;
      var permissions = this.permissions;

      if (permissions) {
        return permissions.includes(parseInt(id));
      }
    },
    hasPullAccessToRepo: function hasPullAccessToRepo(repo) {
      var id = repo.get ? repo.get('id') : repo;
      var permissions = this.pullPermissions;

      if (permissions) {
        return permissions.includes(parseInt(id));
      }
    },
    hasPushAccessToRepo: function hasPushAccessToRepo(repo) {
      var id = repo.get ? repo.get('id') : repo;
      var permissions = this.pushPermissions;

      if (permissions) {
        return permissions.includes(parseInt(id));
      }
    },
    hasPermissionToRepo: function hasPermissionToRepo(repo, permission) {
      var permissions = repo.get ? repo.get('permissions') : null;

      if (permissions) {
        return permissions[permission] || false;
      }
    },
    sync: function sync(isOrganization) {
      var _this = this;

      this.set('isSyncing', true);
      this.set('applyFilterRepos', !isOrganization);
      return this.api.post("/user/".concat(this.id, "/sync")).then(function () {
        return _this.poll();
      });
    },
    schedulePoll: function schedulePoll() {
      var _this2 = this;

      Ember.run.later(function () {
        return _this2.isSyncing && _this2.poll();
      }, _environment.default.intervals.syncingPolling);
    },
    poll: function poll() {
      var _this3 = this;

      return this.reload().then(function () {
        if (_this3.isSyncing) {
          _this3.schedulePoll();
        } else {
          var enterprise = !!_this3.get('features.enterpriseVersion');

          _this3.permissionsService.fetchPermissions.perform();

          if (!enterprise) {
            _this3.wizardStateService.fetch.perform();
          }

          _this3.accounts.fetchOrganizations.perform();

          if (!enterprise) {
            _this3.accounts.fetchSubscriptions.perform();

            _this3.accounts.fetchV2Subscriptions.perform();
          }

          _this3.applyReposFilter();

          Travis.trigger('user:synced', _this3);

          _this3.set('isSyncing', false);
        }
      });
    },
    joinMigrateBeta: function joinMigrateBeta() {
      var _this4 = this;

      var orgs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var organizations = orgs.mapBy('login');
      return this.api.post("/user/".concat(this.id, "/beta_migration_request"), {
        data: {
          organizations: organizations
        }
      }).then(function () {
        return _this4.fetchBetaMigrationRequests();
      });
    },
    reload: function reload() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var authToken = this.authToken;
      return this.store.queryRecord('user', Object.assign({}, options, {
        current: true,
        authToken: authToken
      }));
    },
    applyReposFilter: function applyReposFilter() {
      if (this.applyFilterRepos) {
        var filterTerm = this.get('githubAppsRepositories.filterTerm');
        return this.githubAppsRepositories.applyFilter(filterTerm || '');
      }
    }
  });

  _exports.default = _default;
});