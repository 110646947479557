define("travis/models/v2-billing-info", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    company: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    address2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    vatId: (0, _model.attr)('string'),
    billingEmail: (0, _model.attr)('string'),
    hasLocalRegistration: (0, _model.attr)('boolean'),
    subscription: (0, _model.belongsTo)('v2-subscription')
  });

  _exports.default = _default;
});