define("travis/routes/request-user-confirmation", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.auth.signedOut) return;
      var id = this.auth.currentUser.id;
      this.api.get("/auth/request_confirmation/".concat(id), {
        'travisApiVersion': null
      });
    }
  });

  _exports.default = _default;
});