define("travis/adapters/user", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _v.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var isQueryingCurrentUser = requestType === 'queryRecord' && query.current === true;
      var isUpdatingCurrentUser = requestType === 'updateRecord' && !id;
      (false && !(isQueryingCurrentUser || isUpdatingCurrentUser) && Ember.assert('Invalid parameters for /user request', isQueryingCurrentUser || isUpdatingCurrentUser));
      return "".concat(this.urlPrefix(), "/user?include=user.collaborator");
    },
    // This overrides the parent implementation to ignore the query parameters
    queryRecord: function queryRecord(store, type, _ref) {
      var authToken = _ref.authToken,
          query = _objectWithoutProperties(_ref, ["authToken"]);

      var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
      return this.ajax(url, 'GET', {
        data: {
          include: query.included
        },
        authToken: authToken
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);
      var data = serializer.serialize(snapshot);
      var url = this.buildURL(type.modelName, null, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    },
    ajaxOptions: function ajaxOptions(url, type, _ref2) {
      var authToken = _ref2.authToken,
          options = _objectWithoutProperties(_ref2, ["authToken"]);

      var hash = this._super(url, type, options);

      if (authToken) {
        hash.headers = hash.headers || {};
        hash.headers['Authorization'] = "token ".concat(authToken);
      }

      return hash;
    }
  });

  _exports.default = _default;
});