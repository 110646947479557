define("travis/templates/confirm-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kHk/QG1",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[],[[\"@layoutName\"],[\"layouts/center\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"error-text\"],[8],[0,\"\\n    Your confirmation link is expired or invalid.\\n    We need you to verify your contact address. Please check your email to retrieve your confirmation link.\\n\"],[4,\"if\",[[24,[\"model\",\"signedIn\"]]],null,{\"statements\":[[0,\"     If you need to a new confirmation email, please\\n    \"],[7,\"a\",true],[10,\"class\",\"link\"],[10,\"href\",\"/account/preferences\"],[10,\"target\",\"_blank\"],[8],[0,\"GENERATE NEW TOKEN\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"     Log in to generate the new token.\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/confirm-user.hbs"
    }
  });

  _exports.default = _default;
});