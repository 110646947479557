define("travis/models/v2-plan-config", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    startingPrice: (0, _model.attr)('number'),
    startingUsers: (0, _model.attr)('number'),
    privateCredits: (0, _model.attr)('number'),
    publicCredits: (0, _model.attr)('number'),
    concurrencyLimit: (0, _model.attr)('number'),
    planType: (0, _model.attr)('string'),
    availableStandaloneAddons: (0, _model.attr)(),
    annual: (0, _model.attr)('boolean'),
    autoRefillThresholds: (0, _model.attr)(),
    autoRefillAmounts: (0, _model.attr)(),
    trialPlan: (0, _model.attr)(),
    isFree: Ember.computed.equal('startingPrice', 0),
    isTrial: Ember.computed.equal('trialPlan', true),
    isProTier: Ember.computed.equal('id', 'standard_tier_plan'),
    isStandardTier: Ember.computed.equal('id', 'pro_tier_plan'),
    isUnlimitedUsers: Ember.computed.equal('startingUsers', 999999),
    isAnnual: Ember.computed.equal('annual', true),
    privateCreditsTotal: Ember.computed('privateCredits', 'isAnnual', function () {
      return this.isAnnual ? this.privateCredits * 12 : this.privateCredits;
    }),
    addonConfigs: (0, _model.attr)(),
    hasCreditAddons: Ember.computed('addonConfigs', 'addonConfigs.@each.type', function () {
      return this.addonConfigs.filter(function (addon) {
        return addon.type === 'credit_private';
      }).length > 0;
    }),
    hasOSSCreditAddons: Ember.computed('addonConfigs', 'addonConfigs.@each.type', function () {
      return this.addonConfigs.filter(function (addon) {
        return addon.type === 'credit_public';
      }).length > 0;
    }),
    hasUserLicenseAddons: Ember.computed('addonConfigs', 'addonConfigs.@each.type', function () {
      return this.addonConfigs.filter(function (addon) {
        return addon.type === 'user_license';
      }).length > 0;
    }),
    hasCredits: Ember.computed.or('hasCreditAddons', 'hasOSSCreditAddons'),
    isNotStandardOrProTier: Ember.computed('isProTier', 'isStandardTier', function () {
      return !(this.isProTier || this.isStandardTier);
    })
  });

  _exports.default = _default;
});