define("travis/components/dialogs/user-statistics-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    dateCenter: null,
    dateRange: null,
    showDatePicker: false,
    perPage: 10,
    users: Ember.computed.reads('owner.executionsPerSender'),
    usersCount: Ember.computed.reads('owner.executionsPerSender.length'),
    init: function init() {
      this._super.apply(this, arguments);

      this.page = 1;
    },
    showPrev: Ember.computed('page', 'usersCount', function () {
      return this.page > 1;
    }),
    showNext: Ember.computed('page', 'usersCount', function () {
      return this.page < this.maxPages;
    }),
    maxPages: Ember.computed('usersCount', function () {
      return Math.ceil(this.usersCount / this.perPage);
    }),
    usersToShow: Ember.computed('users', 'page', function () {
      return this.users.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    }),
    actions: {
      datePicker: function datePicker() {
        this.set('showDatePicker', !this.showDatePicker);

        if (!this.showDatePicker) {
          this.account.fetchExecutionsPerSender.perform((0, _moment.default)(this.dateRange.start).format('YYYY-MM-DD'), (0, _moment.default)(this.dateRange.end || this.dateRange.start).format('YYYY-MM-DD'));
        }
      },
      setPage: function setPage(newPage) {
        this.set('page', newPage);
      }
    }
  });

  _exports.default = _default;
});