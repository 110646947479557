define("travis/templates/layouts/activation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShJbFm/j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"wrapper layout-activation\"],[8],[0,\"\\n  \"],[5,\"top-bar\",[],[[\"@isActivation\"],[\"true\"]]],[0,\"\\n  \"],[5,\"flash-display\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"main\"],[10,\"class\",\"main\"],[10,\"role\",\"main\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"activation-section\"],[[28,\"component\",[\"layouts/activation-section\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/layouts/activation.hbs"
    }
  });

  _exports.default = _default;
});