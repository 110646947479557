define("travis/templates/components/billing/subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z4JJfmxm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"billing-subscription\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"isProcessCompleted\"]],[23,0,[\"hasV2Subscription\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"billing/summary-v2\",[],[[\"@subscription\",\"@showPlansSelector\",\"@showAddonsSelector\",\"@selectedPlan\",\"@account\"],[[23,0,[\"v2subscription\"]],[23,0,[\"showPlansSelector\"]],[23,0,[\"showAddonsSelector\"]],[23,0,[\"selectedPlan\"]],[23,0,[\"account\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"billing/summary\",[],[[\"@subscription\",\"@showPlansSelector\",\"@selectedPlan\",\"@account\",\"@isProcessCompleted\"],[[23,0,[\"subscription\"]],[23,0,[\"showPlansSelector\"]],[23,0,[\"selectedPlan\"]],[23,0,[\"account\"]],[23,0,[\"isProcessCompleted\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"subscription\",\"isGithubResubscribable\"]],[28,\"and\",[[23,0,[\"isV2SubscriptionEmpty\"]],[28,\"not\",[[23,0,[\"hasExpiredStripeSubscription\"]]],null]],null]],null]],null,{\"statements\":[[0,\"    \"],[5,\"billing/process\",[],[[\"@account\",\"@showPlansSelector\",\"@subscription\",\"@selectedPlan\",\"@isProcessCompleted\"],[[23,0,[\"account\"]],true,[23,0,[\"newSubscription\"]],[23,0,[\"selectedPlan\"]],[23,0,[\"isProcessCompleted\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/subscription.hbs"
    }
  });

  _exports.default = _default;
});