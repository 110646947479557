define("travis/templates/components/flashes/negative-balance-private-and-public", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1fl5fwVi",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"flash ember-view\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"warning below-top-bar ember-view\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"flash-message\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"message\"],[8],[0,\"\\n        Builds have been temporarily disabled for private and public repositories due to a negative credit balance. \\n        Please go to the \\n\"],[4,\"if\",[[23,1,[\"isUser\"]]],null,{\"statements\":[[0,\"          \"],[5,\"link-to\",[],[[\"@route\"],[\"account.billing\"]],{\"statements\":[[0,\"Plan page\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"organization.billing\",[23,1,[\"owner\"]]]],{\"statements\":[[0,\"Plan page\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        to replenish your credit balance.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flashes/negative-balance-private-and-public.hbs"
    }
  });

  _exports.default = _default;
});