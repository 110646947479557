define("travis/components/jobs-item", ["exports", "travis/utils/job-config-arch", "travis/utils/job-config-language"], function (_exports, _jobConfigArch, _jobConfigLanguage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['job.state'],
    classNames: ['jobs-item'],
    languages: Ember.computed('job.config.content', function () {
      var config = this.get('job.config.content');
      return (0, _jobConfigLanguage.default)(config);
    }),
    name: Ember.computed('job.config.content.name', function () {
      var name = this.get('job.config.content.name');

      if (name) {
        return name;
      }
    }),
    globalEnv: Ember.computed.reads('build.request.config.env.global'),
    jobEnv: Ember.computed.reads('job.config.content.env'),
    gemfile: Ember.computed.reads('job.config.content.gemfile'),
    environment: Ember.computed('globalEnv', 'jobEnv', 'gemfile', function () {
      if (this.jobEnv) {
        var globalEnv = this.globalEnv || [];

        var join = function join(vars, pair) {
          return vars.concat([pair.join('=')]);
        };

        var vars = globalEnv.reduce(function (vars, obj) {
          return Object.entries(obj).reduce(join, vars);
        }, []);
        return vars.reduce(function (env, str) {
          return env.replace(str, '');
        }, this.jobEnv);
      } else if (this.gemfile) {
        return "Gemfile: ".concat(this.gemfile);
      }
    }),
    os: Ember.computed.reads('job.os'),
    osIcon: Ember.computed('os', function () {
      var os = this.os;

      if (os === 'linux') {
        return 'icon-linux';
      } else if (os === 'freebsd') {
        return 'icon-freebsd';
      } else if (os === 'osx') {
        return 'icon-mac';
      } else if (os === 'windows') {
        return 'icon-windows';
      } else {
        return 'help';
      }
    }),
    osVersion: Ember.computed.reads('job.osVersion'),
    arch: Ember.computed('job.config.content.arch', function () {
      var config = this.get('job.config.content');
      return (0, _jobConfigArch.default)(config);
    }),
    serverTypeIcon: Ember.computed.reads('repo.serverType'),
    serverType: Ember.computed('repo.serverType', function () {
      var serverType = this.get('repo.serverType');
      if (!serverType) return '';

      if (serverType === 'svn') {
        return 'SVN';
      } else {
        return serverType.capitalize();
      }
    })
  });

  _exports.default = _default;
});