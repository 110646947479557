define("travis/components/billing-summary-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    account: null,
    subcription: null,
    trial: Ember.computed.reads('account.trial'),
    isGithubTrial: Ember.computed.and('subscription.isGithub', 'trial.hasActiveTrial'),
    hasGithubTrialEnded: Ember.computed.and('subscription.isGithub', 'trial.isEnded'),
    noSubscription: Ember.computed.empty('subscription'),
    isDefaultEducationView: Ember.computed('subscription', 'account.education', 'subscription.plan_name', function () {
      return this.get('subscription') && !Ember.isEmpty(this.get('subscription')) && this.get('account.education');
    })
  });

  _exports.default = _default;
});