define("travis/routes/account-activation", ["exports", "travis/routes/simple-layout"], function (_exports, _simpleLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _simpleLayout.default.extend({
    auth: Ember.inject.service(),
    accounts: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    stripe: Ember.inject.service(),
    storage: Ember.inject.service(),
    store: Ember.inject.service(),
    wizardStateService: Ember.inject.service('wizard-state'),
    activate: function activate() {
      if (this.storage.wizardStep < 1) {
        this.storage.wizardStep = 1;
        this.wizardStateService.update.perform(1);
      }
    },
    deactivate: function deactivate() {
      /* let step = this.storage.wizardStep;
      if (step == 2 || step == 3) this.transitionTo('/account/repositories');*/
    },
    title: 'Travis CI - Select Plan',
    beforeModel: function beforeModel() {
      return this.stripe.load();
    },
    model: function model() {
      this.wizardStateService.fetch.perform();
      this.accounts.user.fetchV2Plans.perform();
      return this.accounts.user;
    }
  });

  _exports.default = _default;
});