define("travis/components/account-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['account-token'],
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    localStorage: Ember.inject.service('storage'),
    authStorage: Ember.computed.reads('localStorage.auth'),
    flashes: Ember.inject.service(),
    tokenIsVisible: false,
    showCopySuccess: false,
    showRegenerateButton: false,
    actions: {
      tokenVisibility: function tokenVisibility() {
        if (this.showCopySuccess) {
          this.toggleProperty('showCopySuccess');
        }

        this.toggleProperty('tokenIsVisible');
      },
      copyTokenSuccessful: function copyTokenSuccessful() {
        if (!this.showCopySuccess) {
          this.toggleProperty('showCopySuccess');
        }
      },
      regenerateToken: function regenerateToken() {
        var _this = this;

        this.api.patch('/access_token').then(function (data) {
          _this.auth.handleTokenRegeneration(data['token']);

          _this.flashes.success('Token successfully regenerated!');
        }).catch(function () {
          _this.flashes.error('There was an error regenerating the token.');
        });
      }
    }
  });

  _exports.default = _default;
});