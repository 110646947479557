define("travis/components/billing/summary-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscription: null,
    account: null,
    selectedPlan: null,
    isEditPlanLoading: Ember.computed.reads('subscription.changePlan.isLoading'),
    isIncomplete: Ember.computed.reads('subscription.isIncomplete'),
    isComplete: Ember.computed.not('isIncomplete'),
    authenticationNotRequired: Ember.computed.not('subscription.clientSecret'),
    isPending: Ember.computed.and('subscription.isPending', 'authenticationNotRequired'),
    isNotCanceled: Ember.computed.not('isCanceled'),
    isNotPending: Ember.computed.not('isPending'),
    hasNotExpired: Ember.computed.not('isExpired'),
    isCanceled: Ember.computed.reads('subscription.isCanceled'),
    isSubscribed: Ember.computed.reads('subscription.isSubscribed'),
    isExpired: Ember.computed.or('subscription.isExpired', 'subscription.subscriptionExpiredByDate'),
    canceledOrExpired: Ember.computed.or('isExpired', 'isCanceled'),
    isCompleteAndNotExpired: Ember.computed.and('hasNotExpired', 'isComplete'),
    trial: Ember.computed.reads('account.trial'),
    isGithubSubscription: Ember.computed.reads('subscription.isGithub'),
    expiredStripeSubscription: Ember.computed.reads('account.expiredStripeSubscription'),
    hasExpiredStripeSubscription: Ember.computed.bool('expiredStripeSubscription'),
    showPlanInfo: Ember.computed('showPlansSelector', 'showAddonsSelector', function () {
      return !this.showPlansSelector && !this.showAddonsSelector;
    }),
    showUserManagementModal: false
  });

  _exports.default = _default;
});