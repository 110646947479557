define("travis/components/ui-kit/link", ["exports", "travis/utils/ui-kit/assertions", "travis/components/ui-kit/text", "travis/utils/ui-kit/prefix", "travis/utils/ui-kit/concat"], function (_exports, _assertions, _text, _prefix, _concat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    color: _text.DEFAULT_TEXT_COLOR,
    colorClass: (0, _prefix.default)('color', 'text', {
      dictionary: _text.TEXT_COLORS
    }),
    // Public interface
    variant: 'link-underlined',
    allClasses: (0, _concat.default)('colorClass'),
    href: null,
    rel: 'noopener noreferrer',
    target: '_blank',
    route: null,
    disabled: 'false',
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.checkColor)({
        value: this.color,
        dictionary: _text.COLORS,
        component: 'Link'
      });
    }
  });

  _exports.default = _default;
});