define("travis/components/billing/user-usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscription: null,
    account: null,
    usedUsers: Ember.computed.reads('subscription.usedUsers'),
    addonUsage: Ember.computed.reads('subscription.addonUsage.user'),
    usersUsageReceived: Ember.computed.reads('account.allowance.isFulfilled'),
    usersUsageRejected: Ember.computed.reads('account.allowance.isRejected'),
    usersUsage: Ember.computed('account.allowance.userUsage', 'addonUsage', function () {
      var userUsage = this.get('account').get('allowance').get('userUsage');

      if (userUsage === undefined) {
        return true;
      }

      return userUsage && this.addonUsage.usedCredits < this.addonUsage.totalCredits;
    }),
    pendingUserLicenses: Ember.computed.reads('account.allowance.pendingUserLicenses')
  });

  _exports.default = _default;
});