define("travis/templates/organization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EC2MJmWC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"travis-layout\",[[12,\"class\",\"profile-view\"]],[[\"@layoutName\",\"@activeModel\"],[\"layouts/profile\",[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[5,\"profile-nav\",[],[[\"@activeModel\"],[[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/organization.hbs"
    }
  });

  _exports.default = _default;
});