define("travis/mixins/controller/plan_usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    storage: Ember.inject.service(),
    account: Ember.computed.reads('model.account'),
    subscription: Ember.computed.reads('account.v2subscription')
  });

  _exports.default = _default;
});