define("travis/components/temporary-announcement-banner", ["exports", "travis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    message: '',
    enabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('enabled', _environment.default.tempBanner.tempBannerEnabled === 'true');
      this.set('message', _environment.default.tempBanner.tempBannerMessage || '');
    }
  });

  _exports.default = _default;
});