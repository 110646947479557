define("travis/components/billing/selected-addon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    total: Ember.computed.reads('selectedAddon.price')
  });

  _exports.default = _default;
});