define("travis/models/scan-result", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    e: (0, _model.attr)('string'),
    repo: (0, _model.belongsTo)('repo'),
    createdAt: (0, _model.attr)('date'),
    formattedContent: (0, _model.attr)('string'),
    jobId: (0, _model.attr)('number'),
    buildId: (0, _model.attr)('number'),
    buildCreatedBy: (0, _model.belongsTo)('user'),
    jobNumber: (0, _model.attr)('string'),
    buildNumber: (0, _model.attr)('string'),
    jobFinishedAt: (0, _model.attr)('date'),
    commitSha: (0, _model.attr)('string'),
    commitCompareUrl: (0, _model.attr)('string'),
    commitBranch: (0, _model.attr)('string')
  });

  _exports.default = _default;
});