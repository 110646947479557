define("travis/routes/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    title: 'Travis CI - Help Center',
    afterModel: function afterModel() {
      if (this.auth.signedIn) {
        return this.auth.reloadCurrentUser(['user.emails']);
      }
    }
  });

  _exports.default = _default;
});