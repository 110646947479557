define("travis/templates/scan-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lDtaE7Cf",
    "block": "{\"symbols\":[],\"statements\":[[5,\"scan-result-list\",[],[[\"@repo\"],[[23,0,[\"repo\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/scan-results.hbs"
    }
  });

  _exports.default = _default;
});