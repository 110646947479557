define("travis/adapters/billing-info", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    // Clean the record without submitting
    // Submitting is done in the v2-subscription adapter
    updateRecord: function updateRecord(store, type, snapshot) {
      return true;
    }
  });

  _exports.default = _default;
});