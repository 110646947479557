define("travis/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseWithDefault = _parseWithDefault;
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service('storage/auth'),
    utm: Ember.inject.service('storage/utm'),

    get billingStep() {
      return +this.getItem('travis.billing_step');
    },

    set billingStep(value) {
      this.setItem('travis.billing_step', +value);
    },

    get wizardStep() {
      return +this.getItem('travis.wizard_step');
    },

    set wizardStep(value) {
      this.setItem('travis.wizard_step', +value);
    },

    get selectedPlanId() {
      return this.getItem('travis.billing_selected_plan_id');
    },

    set selectedPlanId(value) {
      this.setItem('travis.billing_selected_plan_id', value);
    },

    clearSelectedPlanId: function clearSelectedPlanId() {
      this.removeItem('travis.billing_selected_plan_id');
    },

    get billingInfo() {
      return this.parseWithDefault('travis.billing_info', {});
    },

    set billingInfo(value) {
      this.setItem('travis.billing_info', JSON.stringify(value));
    },

    get billingPlan() {
      return this.parseWithDefault('travis.billing_plan', {});
    },

    set billingPlan(value) {
      this.setItem('travis.billing_plan', JSON.stringify(value));
    },

    clearPreferencesData: function clearPreferencesData() {
      this.removeItem('travis.features');
    },
    clearBillingData: function clearBillingData() {
      this.removeItem('travis.billing_step');
      this.removeItem('travis.billing_plan');
      this.removeItem('travis.billing_info');
    },
    parseWithDefault: function parseWithDefault(key, defaultValue) {
      return _parseWithDefault(this.getItem(key), defaultValue);
    },
    // method proxies
    getItem: function getItem(key) {
      return this.storage.getItem(key);
    },
    setItem: function setItem(key, value) {
      return this.storage.setItem(key, value);
    },
    removeItem: function removeItem(key) {
      return this.storage.removeItem(key);
    },
    clear: function clear() {
      return this.storage.clear();
    },

    get storage() {
      return window.localStorage;
    }

  });

  _exports.default = _default;

  function _parseWithDefault(json, defaultValue) {
    try {
      return JSON.parse(json) || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }
});