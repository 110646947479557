define("travis/templates/components/ui-kit/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+y0uwCkN",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"route\"]]],null,{\"statements\":[[5,\"link-to\",[[12,\"class\",[23,0,[\"allClasses\"]]],[13,1]],[[\"@route\"],[[23,0,[\"route\"]]]],{\"statements\":[[1,\"\",false],[5,\"ui-kit/text\",[],[[\"@tag\",\"@variant\",\"@color\"],[\"span\",[23,0,[\"variant\"]],[23,0,[\"color\"]]]],{\"statements\":[[14,2]],\"parameters\":[]}],[1,\"\",false]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[5,\"ui-kit/text\",[[12,\"href\",[23,0,[\"href\"]]],[12,\"rel\",[23,0,[\"rel\"]]],[12,\"target\",[23,0,[\"target\"]]],[13,1]],[[\"@tag\",\"@disabled\",\"@variant\",\"@color\",\"@size\"],[\"a\",[23,0,[\"disabled\"]],[23,0,[\"variant\"]],[23,0,[\"color\"]],[23,0,[\"size\"]]]],{\"statements\":[[14,2]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/ui-kit/link.hbs"
    }
  });

  _exports.default = _default;
});