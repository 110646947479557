define("travis/adapters/v2-subscription", ["exports", "travis/adapters/v3", "@ember-data/adapter/error"], function (_exports, _v, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      var url = this._super.apply(this, arguments);

      if (requestType === 'updateRecord') {
        url = "".concat(url, "/address");
      }

      return url;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = this.serialize(snapshot, {
        update: true
      });
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PATCH', {
        data: data
      });
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 422 && payload.error_message) {
        return new _error.InvalidError([{
          'source': {
            'pointer': '/data/attributes/validationErrors'
          },
          'detail': payload.error_message
        }]);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});