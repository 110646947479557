define("travis/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency(value) {
    var parsedValue = Number(value);

    if (isNaN(parsedValue)) {
      return value;
    }

    return parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});