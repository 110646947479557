define("travis/templates/components/billing/user-usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gz1cIaZI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"billing-subscription-user-usage\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    Users\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"used-users\"],[8],[0,\"\\n    \"],[1,[23,0,[\"usedUsers\"]],false],[0,\" Unique users who are running builds\\n  \"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"desc\"],[8],[0,\"\\n    A user is anyone who triggers the use of the compute resources you will be charged monthly for.\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"pendingUserLicenses\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[10,\"class\",\"warning\"],[8],[0,\"\\n      You have unpaid Invoice. Please check your invoices\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"not\",[[23,0,[\"usersUsage\"]]],null],[23,0,[\"usersUsageReceived\"]],[28,\"not\",[[23,0,[\"usersUsageRejected\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"warning\"],[8],[0,\"\\n        You exceeded the number of users allowed for your plan. Please switch to a bigger plan\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/billing/user-usage.hbs"
    }
  });

  _exports.default = _default;
});