define("travis/components/billing/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    accounts: Ember.inject.service(),
    account: null,
    subscription: Ember.computed.reads('account.subscription'),
    v2subscription: Ember.computed.reads('account.v2subscription'),
    isV2SubscriptionEmpty: Ember.computed.empty('v2subscription'),
    isSubscriptionEmpty: Ember.computed.empty('subscription'),
    isSubscriptionsEmpty: Ember.computed.and('isSubscriptionEmpty', 'isV2SubscriptionEmpty'),
    hasV2Subscription: Ember.computed.not('isV2SubscriptionEmpty'),
    trial: Ember.computed.reads('account.trial'),
    isEducationalAccount: Ember.computed.bool('account.education'),
    isNotEducationalAccount: Ember.computed.not('isEducationalAccount'),
    isTrial: Ember.computed.and('isSubscriptionsEmpty', 'isNotEducationalAccount'),
    isManual: Ember.computed.bool('subscription.isManual'),
    isManaged: Ember.computed.bool('subscription.managedSubscription'),
    isEducation: Ember.computed.and('isSubscriptionsEmpty', 'isEducationalAccount'),
    isSubscription: Ember.computed('isManaged', 'hasV2Subscription', 'isTrialProcessCompleted', 'isEduProcessCompleted', function () {
      return (this.isManaged || this.hasV2Subscription) && this.isTrialProcessCompleted && this.isEduProcessCompleted;
    }),
    showInvoices: Ember.computed('showPlansSelector', 'showAddonsSelector', function () {
      return !this.showPlansSelector && !this.showAddonsSelector && this.invoices;
    }),
    isLoading: Ember.computed.or('accounts.fetchSubscriptions.isRunning', 'accounts.fetchV2Subscriptions.isRunning'),
    showPlansSelector: false,
    showAddonsSelector: false,
    isTrialProcessCompleted: Ember.computed(function () {
      return !this.isTrial;
    }),
    isEduProcessCompleted: Ember.computed(function () {
      return !this.isEducation;
    }),
    newV2Subscription: Ember.computed(function () {
      var plan = this.store.createRecord('v2-plan-config');
      var billingInfo = this.store.createRecord('v2-billing-info');
      var creditCardInfo = this.store.createRecord('v2-credit-card-info');
      billingInfo.setProperties({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
        billingEmail: ''
      });
      creditCardInfo.setProperties({
        token: '',
        lastDigits: ''
      });
      return this.store.createRecord('v2-subscription', {
        billingInfo: billingInfo,
        plan: plan,
        creditCardInfo: creditCardInfo
      });
    }),
    invoices: Ember.computed('subscription.id', 'v2subscription.id', function () {
      var subscriptionId = this.isV2SubscriptionEmpty ? this.get('subscription.id') : this.get('v2subscription.id');
      var type = this.isV2SubscriptionEmpty ? 1 : 2;

      if (subscriptionId) {
        return this.store.query('invoice', {
          type: type,
          subscriptionId: subscriptionId
        });
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});