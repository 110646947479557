define("travis/serializers/plan", ["exports", "travis/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pushPayload: function pushPayload(store, payload) {
      var modelClass = store.modelFor('plan');
      var json = this.normalizeArrayResponse(store, modelClass, payload);
      store.push(json);
    }
  });

  _exports.default = _default;
});