define("travis/routes/account/payment_details", ["exports", "travis/routes/basic", "travis/mixins/route/account/payment_details"], function (_exports, _basic, _payment_details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend(_payment_details.default, {
    model: function model() {
      return Ember.RSVP.hash({
        account: this.modelFor('account')
      });
    }
  });

  _exports.default = _default;
});