define("travis/models/invoice", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    url: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    amountDue: (0, _model.attr)('number'),
    ccLastDigits: (0, _model.attr)('string'),
    subscription: (0, _model.belongsTo)('subscription'),
    year: Ember.computed('createdAt', function () {
      return this.createdAt.getFullYear();
    }),
    isUnpaid: Ember.computed('status', function () {
      return this.status !== 'paid';
    }),
    presentableStatus: Ember.computed('status', function () {
      if (this.status === 'open') return 'unpaid';
      return this.status;
    })
  });

  _exports.default = _default;
});