define("travis/components/billing/credit-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    subscription: null,
    account: null,
    creditsTab: 0,
    creditsPublicTotal: Ember.computed.reads('subscription.addonUsage.public.totalCredits'),
    creditsPublicUsed: Ember.computed.reads('subscription.addonUsage.public.usedCredits'),
    creditsPublicAvailable: Ember.computed.reads('subscription.addonUsage.public.remainingCredits'),
    creditsPublicValidDate: Ember.computed.reads('subscription.addonUsage.public.validDate'),
    creditsPrivateTotal: Ember.computed.reads('subscription.addonUsage.private.totalCredits'),
    creditsPrivateUsed: Ember.computed.reads('subscription.addonUsage.private.usedCredits'),
    creditsPrivateAvailable: Ember.computed.reads('subscription.addonUsage.private.remainingCredits'),
    creditsPrivatePurchaseDate: Ember.computed.reads('subscription.addonUsage.private.purchaseDate'),
    creditsValidityDate: Ember.computed('subscription.validTo', function () {
      var date = new Date(this.subscription.validTo);
      date.setMonth(this.subscription.validTo.getMonth() + 1);
      return date;
    }),
    creditsPrivateValidDate: Ember.computed.reads('subscription.addonUsage.private.validDate'),
    creditsTotal: Ember.computed('creditsTab', 'creditsPublicTotal', 'creditsPrivateTotal', function () {
      if (this.creditsTab === 1) return this.creditsPublicTotal;
      return this.creditsPrivateTotal;
    }),
    creditsUsed: Ember.computed('creditsTab', 'creditsPublicUsed', 'creditsPrivateUsed', function () {
      if (this.creditsTab === 1) return this.creditsPublicUsed;
      return this.creditsPrivateUsed;
    }),
    creditsAvailable: Ember.computed('creditsTab', 'creditsPublicAvailable', 'creditsPrivateAvailable', function () {
      if (this.creditsTab === 1) return this.creditsPublicAvailable;
      return this.creditsPrivateAvailable;
    }),
    creditsUsedPercentage: Ember.computed('creditsAvailable', 'creditsTotal', function () {
      return this.creditsAvailable / this.creditsTotal * 100;
    }),
    isNegativeBalance: Ember.computed('creditsAvailable', function () {
      return this.creditsAvailable < 0;
    }),
    actions: {
      setPrivateCreditsTab: function setPrivateCreditsTab() {
        this.set('creditsTab', 0);
      },
      setOSSCreditsTab: function setOSSCreditsTab() {
        this.set('creditsTab', 1);
      }
    }
  });

  _exports.default = _default;
});