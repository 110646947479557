define("travis/helpers/commit-link", ["exports", "travis/utils/format-commit"], function (_exports, _formatCommit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var escape = Ember.Handlebars.Utils.escapeExpression;

  var _default = Ember.Helper.extend({
    externalLinks: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          vcsType = _ref2[0],
          slug = _ref2[1],
          commitSha = _ref2[2];

      if (!commitSha) {
        return '';
      }

      var commit = escape((0, _formatCommit.default)(commitSha));

      if (!slug) {
        return commit;
      }

      var _slug$split = slug.split('/'),
          _slug$split2 = _slicedToArray(_slug$split, 2),
          owner = _slug$split2[0],
          repo = _slug$split2[1];

      var slugOwner = slug.split('/')[0];
      var commitUrl = this.externalLinks.commitUrl(vcsType, {
        owner: owner,
        repo: repo,
        commit: commit,
        slugOwner: slugOwner
      });
      var url = escape(commitUrl);
      var string = "<a class=\"github-link only-on-hover\" href=\"".concat(url, "\">").concat(commit, "</a>");
      return new Ember.String.htmlSafe(string);
    }
  });

  _exports.default = _default;
});