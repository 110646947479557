define("travis/mixins/route/account/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stripe: Ember.inject.service(),
    store: Ember.inject.service(),
    storage: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.stripe.load();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.checkBillingStep();
      controller.set('selectedPlan', this.selectedPlan());
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controller.set('billingStep', 1);
    },
    afterModel: function afterModel(model) {
      if (model && !model.error) {
        model.account.fetchV2Plans.perform();
      }
    },
    selectedPlan: function selectedPlan() {
      var savedPlan = this.storage.billingPlan;
      var selectedPlan = savedPlan && savedPlan.id && this.store.peekRecord('v2-plan-config', savedPlan.id);
      return selectedPlan || this.store.createRecord('v2-plan-config', this.storage.billingPlan);
    },
    checkBillingStep: function checkBillingStep() {
      var billingStepQueryParams = this.controller.get('billingStep');

      if (billingStepQueryParams !== this.storage.billingStep) {
        this.storage.clearBillingData();
      }
    }
  });

  _exports.default = _default;
});