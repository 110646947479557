define("travis/templates/components/org-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oz//OvlW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to-account\",[],[[\"@routeName\",\"@routeModel\",\"@class\"],[[23,0,[\"routeName\"]],[23,0,[\"routeModel\"]],\"org-info\"]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"account-avatar\"],[8],[0,\"\\n    \"],[5,\"user-avatar\",[],[[\"@account\",\"@url\",\"@name\",\"@showSubscriptionStatus\"],[[23,0,[\"account\"]],[23,0,[\"account\",\"avatarUrl\"]],[23,0,[\"name\"]],true]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"account-info\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"account-name\"],[8],[0,\"\\n      \"],[1,[23,0,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showSync\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n      \"],[5,\"sync-button\",[],[[\"@isOrganization\",\"@wizardStep\"],[[23,0,[\"isOrganization\"]],[23,0,[\"wizardStep\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/org-item.hbs"
    }
  });

  _exports.default = _default;
});