define("travis/routes/builds", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    tabStates: Ember.inject.service(),
    auth: Ember.inject.service(),
    activate: function activate() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);

      if (this.get('auth.signedIn')) {
        this.set('tabStates.sidebarTab', 'owned');
      }

      this.set('tabStates.mainTab', 'builds');
    },
    titleToken: function titleToken() {
      return 'Builds';
    },
    model: function model() {
      return this.modelFor('repo').get('builds');
    },
    beforeModel: function beforeModel() {
      var repo = this.modelFor('repo');

      if (repo && !repo.repoOwnerAllowance) {
        repo.fetchRepoOwnerAllowance.perform();
      }
    }
  });

  _exports.default = _default;
});