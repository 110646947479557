define("travis/templates/components/unconfirmed-user-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZQKirOsT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"notice-banner--red align-center\"],[8],[0,\"\\n  Please check your email and confirm your account, before that you will have limited build functions. If you need to generate a new confirmation email, \"],[5,\"link-to\",[],[[\"@route\"],[\"account.settings\"]],{\"statements\":[[0,\"please resend your confirmation email\"]],\"parameters\":[]}],[0,\".\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/unconfirmed-user-banner.hbs"
    }
  });

  _exports.default = _default;
});