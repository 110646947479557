define("travis/templates/components/flash-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N2qdBjw1",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"let\",[[23,0,[\"messages\",\"firstObject\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"type\"]],\"custom\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"data\"],[[23,1,[\"data\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[5,\"flash-item\",[],[[\"@flash\",\"@close\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"closeMessage\"],null]]]],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "travis/templates/components/flash-display.hbs"
    }
  });

  _exports.default = _default;
});