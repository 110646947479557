define("travis/routes/branches", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    repositories: Ember.inject.service(),
    tabStates: Ember.inject.service(),
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    model: function model() {
      var repoId = this.modelFor('repo').get('id');
      var allTheBranches = Ember.ArrayProxy.create();
      var path = "/repo/".concat(repoId, "/branches");
      var includes = 'build.commit,build.created_by&limit=100';
      var url = "".concat(path, "?include=").concat(includes);
      return this.api.get(url).then(function (response) {
        allTheBranches = response.branches;
        return allTheBranches;
      });
    },
    activate: function activate() {
      if (this.get('auth.signedIn')) {
        this.set('tabStates.sidebarTab', 'owned');
        this.set('tabStates.mainTab', 'branches');
      }
    },
    beforeModel: function beforeModel() {
      var repo = this.modelFor('repo');

      if (repo && !repo.repoOwnerAllowance) {
        repo.fetchRepoOwnerAllowance.perform();
      }
    }
  });

  _exports.default = _default;
});