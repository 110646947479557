define("travis/controllers/signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    multiVcs: Ember.inject.service(),
    features: Ember.inject.service(),
    accounts: Ember.computed.reads('auth.accounts'),
    hasAccounts: Ember.computed.gt('accounts.length', 0),
    actions: {
      signIn: function signIn(provider) {
        this.auth.signInWith(provider);
      }
    }
  });

  _exports.default = _default;
});