define("travis/components/billing/process", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var STEPS = {
    ONE: 1,
    TWO: 2,
    THREE: 3
  };

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    account: null,
    steps: Ember.computed(function () {
      return _toConsumableArray(Object.values(STEPS));
    }),
    showCancelButton: false,
    currentStep: Ember.computed(function () {
      return this.storage.billingStep || STEPS.ONE;
    }),
    billingInfoExists: Ember.computed('existingBillingInfo.{firstName,lastName,billingEmail,city,zipCode,country}', function () {
      var billingInfo = this.existingBillingInfo;
      if (billingInfo) return billingInfo.firstName && billingInfo.lastName && billingInfo.billingEmail && billingInfo.address && billingInfo.city && billingInfo.zipCode && billingInfo.country;
      return false;
    }),
    isStepOne: Ember.computed.equal('currentStep', STEPS.ONE),
    isStepTwo: Ember.computed.equal('currentStep', STEPS.TWO),
    isStepThree: Ember.computed.equal('currentStep', STEPS.THREE),
    existingBillingInfo: Ember.computed.reads('subscription.billingInfo'),
    existingCreditCardInfo: Ember.computed.reads('subscription.creditCardInfo'),
    trackButtonClicks: function trackButtonClicks() {
      if (this.currentStep === STEPS.ONE) {
        this.metrics.trackEvent({
          category: 'Subscription',
          action: 'Plan Chosen'
        });
      } else if (this.currentStep === STEPS.TWO) {
        this.metrics.trackEvent({
          category: 'Subscription',
          action: 'Contact Details Filled'
        });
      }
    },
    persistBillingData: function persistBillingData(step) {
      this.storage.billingStep = step;
      this.storage.billingPlan = this.selectedPlan.getProperties(['id', 'name', 'startingPrice', 'startingUsers', 'privateCredits', 'publicCredits', 'concurrencyLimit', 'planType', 'availableStandaloneAddons', 'addonConfigs']);
      this.storage.billingInfo = this.billingInfo;
    },
    updateBillingQueryParams: function updateBillingQueryParams(step) {
      this.router.transitionTo({
        queryParams: {
          billingStep: step
        }
      });
    },
    actions: {
      goToFirstStep: function goToFirstStep() {
        this.set('currentStep', STEPS.ONE);
        this.persistBillingData(STEPS.ONE);
        this.updateBillingQueryParams(STEPS.ONE);
      },
      next: function next() {
        if (this.selectedPlan || this.selectedAddon) {
          this.trackButtonClicks();
          var currentIndex = this.steps.indexOf(this.currentStep);
          var lastIndex = this.steps.length - 1;
          var nextIndex = Math.min(lastIndex, currentIndex + 1);

          if (this.billingInfoExists && this.currentStep === STEPS.ONE || this.selectedPlan.startingPrice === 0) {
            var currentStep = STEPS.THREE;
            this.set('currentStep', currentStep);
            this.set('billingInfo', this.existingBillingInfo);
          } else {
            var _currentStep = this.steps[nextIndex];
            this.set('currentStep', _currentStep);
          }

          this.updateBillingQueryParams(this.currentStep);
          this.persistBillingData(this.currentStep);
        }
      },
      back: function back() {
        var currentIndex = this.steps.indexOf(this.currentStep);
        var prevIndex = Math.max(0, currentIndex - 1);
        var currentStep = this.steps[prevIndex];
        this.set('currentStep', currentStep);
        this.updateBillingQueryParams(currentStep);
        this.persistBillingData(currentStep);
      },
      cancel: function cancel() {
        this.set('currentStep', STEPS.ONE);
        this.updateBillingQueryParams(STEPS.ONE);
      },
      closePlansModal: function closePlansModal() {
        this.set('showPlansSelector', false);
      }
    }
  });

  _exports.default = _default;
});