define("travis/components/billing/postal-address", ["exports", "travis/utils/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countries: _countries.countries,
    states: Ember.computed('country', function () {
      var country = this.country;
      return _countries.states[country];
    }),
    billingInfo: null,
    hasLocalRegistration: Ember.computed.reads('billingInfo.hasLocalRegistration'),
    country: Ember.computed.reads('billingInfo.country'),
    isZeroVatThresholdCountry: Ember.computed('country', function () {
      var country = this.country;
      return !!country && _countries.zeroVatThresholdCountries.includes(country);
    }),
    isNonZeroVatThresholdCountry: Ember.computed('country', function () {
      var country = this.country;
      return !!country && _countries.nonZeroVatThresholdCountries.includes(country);
    }),
    isStateCountry: Ember.computed('country', function () {
      var country = this.country;
      return !!country && _countries.stateCountries.includes(country);
    }),
    isVatMandatory: Ember.computed('isNonZeroVatThresholdCountry', 'hasLocalRegistration', function () {
      var isNonZeroVatThresholdCountry = this.isNonZeroVatThresholdCountry,
          isZeroVatThresholdCountry = this.isZeroVatThresholdCountry,
          hasLocalRegistration = this.hasLocalRegistration;
      return isZeroVatThresholdCountry || (isNonZeroVatThresholdCountry ? hasLocalRegistration : false);
    }),
    showNonZeroVatConfirmation: Ember.computed.reads('isNonZeroVatThresholdCountry'),
    showVatField: Ember.computed('country', 'isNonZeroVatThresholdCountry', 'hasLocalRegistration', function () {
      var country = this.country,
          isNonZeroVatThresholdCountry = this.isNonZeroVatThresholdCountry,
          hasLocalRegistration = this.hasLocalRegistration;
      return country && (isNonZeroVatThresholdCountry ? hasLocalRegistration : true);
    }),
    isStateMandatory: Ember.computed.reads('isStateCountry')
  });

  _exports.default = _default;
});