define("travis/components/ui-kit/button", ["exports", "travis/utils/ui-kit/assertions"], function (_exports, _assertions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COLORS = void 0;

  var _BG_COLORS, _HOVER_BG_COLORS, _LABEL_COLORS;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Public dictionaries
  var COLORS = {
    BLUE: 'blue',
    GREY: 'grey',
    GREY_DARK: 'grey-dark',
    GREEN: 'green',
    RED: 'red-300'
  }; // Private dictionaries

  _exports.COLORS = COLORS;
  var DEFAULT_COLOR = COLORS.BLUE;
  var BG_COLORS = (_BG_COLORS = {}, _defineProperty(_BG_COLORS, COLORS.BLUE, 'blue-400'), _defineProperty(_BG_COLORS, COLORS.GREY, 'grey-700'), _defineProperty(_BG_COLORS, COLORS.GREY_DARK, 'grey-800'), _defineProperty(_BG_COLORS, COLORS.GREEN, 'green-300'), _defineProperty(_BG_COLORS, COLORS.RED, 'red-300'), _defineProperty(_BG_COLORS, "disabled", 'grey-200'), _defineProperty(_BG_COLORS, "invert", 'transparent'), _BG_COLORS);
  var HOVER_BG_COLORS = (_HOVER_BG_COLORS = {}, _defineProperty(_HOVER_BG_COLORS, COLORS.BLUE, 'blue-500'), _defineProperty(_HOVER_BG_COLORS, "".concat(COLORS.BLUE, "-invert"), 'blue-100'), _defineProperty(_HOVER_BG_COLORS, COLORS.GREY, 'grey-800'), _defineProperty(_HOVER_BG_COLORS, "".concat(COLORS.GREY, "-invert"), 'grey-150'), _defineProperty(_HOVER_BG_COLORS, COLORS.GREY_DARK, 'grey-700'), _defineProperty(_HOVER_BG_COLORS, "".concat(COLORS.GREY_DARK, "-invert"), 'grey-150'), _defineProperty(_HOVER_BG_COLORS, COLORS.GREEN, 'green-400'), _defineProperty(_HOVER_BG_COLORS, "".concat(COLORS.GREEN, "-invert"), 'green-100'), _defineProperty(_HOVER_BG_COLORS, COLORS.RED, 'red-300'), _defineProperty(_HOVER_BG_COLORS, "".concat(COLORS.RED, "-invert"), 'red-90'), _HOVER_BG_COLORS);
  var LABEL_COLORS = (_LABEL_COLORS = {}, _defineProperty(_LABEL_COLORS, "".concat(COLORS.BLUE, "-invert"), 'blue-400'), _defineProperty(_LABEL_COLORS, "".concat(COLORS.GREY, "-invert"), 'grey-700'), _defineProperty(_LABEL_COLORS, "".concat(COLORS.GREY_DARK, "-invert"), 'grey-800'), _defineProperty(_LABEL_COLORS, "".concat(COLORS.GREEN, "-invert"), 'green-300'), _defineProperty(_LABEL_COLORS, "".concat(COLORS.RED, "-invert"), 'red-300'), _defineProperty(_LABEL_COLORS, "disabled", 'white'), _defineProperty(_LABEL_COLORS, 'disabled-invert', 'grey-200'), _defineProperty(_LABEL_COLORS, "default", 'white'), _LABEL_COLORS);
  var DEFAULT_WIDTH = 'auto';

  var _default = Ember.Component.extend({
    tagName: '',
    // Public interface
    role: 'button',
    color: DEFAULT_COLOR,
    width: DEFAULT_WIDTH,
    invert: false,
    disabled: false,
    onClick: function onClick() {},
    // Private
    bgColor: Ember.computed('color', 'disabled', 'invert', function () {
      return this.invert ? BG_COLORS['invert'] : this.disabled ? BG_COLORS['disabled'] : BG_COLORS[this.color];
    }),
    hoverBgColor: Ember.computed('color', 'disabled', 'invert', 'bgColor', function () {
      return this.disabled ? this.bgColor : this.invert ? HOVER_BG_COLORS["".concat(this.color, "-invert")] : HOVER_BG_COLORS[this.color];
    }),
    labelColor: Ember.computed('color', 'disabled', 'invert', function () {
      if (this.invert) {
        return this.disabled ? LABEL_COLORS['disabled-invert'] : LABEL_COLORS["".concat(this.color, "-invert")];
      } else {
        return this.disabled ? LABEL_COLORS['disabled'] : LABEL_COLORS[this.color] || LABEL_COLORS['default'];
      }
    }),
    borderColor: Ember.computed('invert', 'bgColor', 'labelColor', function () {
      return this.invert ? this.labelColor : this.bgColor;
    }),
    // Lifecycle
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (0, _assertions.checkDictionary)(this.color, COLORS, '@color', 'Button');
    },
    // Actions
    actions: {
      handleClick: function handleClick() {
        return this.onClick();
      }
    }
  });

  _exports.default = _default;
});