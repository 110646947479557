define("travis/routes/confirm-user", ["exports", "travis/routes/basic"], function (_exports, _basic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basic.default.extend({
    api: Ember.inject.service(),
    auth: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var token = transition.to.params.token;
      var that = this;
      this.api.get("/auth/confirm_user/".concat(token), {
        'travisApiVersion': null
      }).then(function () {
        if (_this.auth.signedIn) {
          that.auth.sync().then(function () {
            return that.transitionTo('/');
          });
        } else {
          that.transitionTo('/');
        }
      }).catch(function (_error) {
        return _error;
      });
    },
    model: function model() {
      return {
        signedIn: this.auth.signedIn
      };
    }
  });

  _exports.default = _default;
});