define("travis/adapters/repo", ["exports", "travis/adapters/v3"], function (_exports, _v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _v.default.extend({
    defaultSerializer: '-repo',
    includes: ['build.branch', 'build.commit', 'build.created_by', 'build.request', 'repository.current_build', 'repository.default_branch', 'repository.email_subscribed', 'owner.github_id', 'owner.installation'].join(','),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var prefix = this.urlPrefix();

      if (query) {
        var provider = query.provider,
            slug = query.slug,
            serverType = query.serverType,
            custom = query.custom;
        var providerPrefix = provider ? "".concat(provider, "/") : '';
        delete query.provider;
        delete query.slug;
        delete query.custom;
        delete query.serverType; // fetch repo by slug

        if (!id && slug) {
          var url = "".concat(prefix, "/repo/").concat(providerPrefix).concat(encodeURIComponent(slug));

          if (serverType) {
            url = "".concat(url, "?server_type=").concat(serverType);
          }

          return url;
        }

        if (custom && custom.type === 'byOwner') {
          var owner = custom.owner;
          return "".concat(prefix, "/owner/").concat(providerPrefix).concat(owner, "/repos");
        }
      }

      return this._super(modelName, id, snapshot, requestType, query);
    },
    activate: function activate(id) {
      var prefix = this.urlPrefix();
      var url = "".concat(prefix, "/repo/").concat(id, "/activate");
      return this.ajax(url, 'POST');
    },
    deactivate: function deactivate(id) {
      var prefix = this.urlPrefix();
      var url = "".concat(prefix, "/repo/").concat(id, "/deactivate");
      return this.ajax(url, 'POST');
    }
  });

  _exports.default = _default;
});