define("travis/components/repository-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    features: Ember.inject.service(),
    flashes: Ember.inject.service(),
    isProVersion: Ember.computed.reads('features.proVersion'),
    isShowingTriggerBuildModal: false,
    isShowingStatusBadgeModal: false,
    currentUser: Ember.computed.alias('auth.currentUser'),
    userRoMode: Ember.computed.reads('currentUser.roMode'),
    scansEnabled: Ember.computed.reads('features.logScanner'),
    repositoryProvider: Ember.computed('repo.provider', function () {
      return this.repo.provider.capitalize();
    }),
    repositoryType: Ember.computed('repo.serverType', function () {
      switch (this.repo.serverType) {
        case 'git':
          return 'GIT';

        case 'subversion':
          return 'SVN';

        case 'perforce':
          return 'P4';
      }
    }),
    repoUrl: Ember.computed('repo.{ownerName,slug,vcsName,vcsType}', function () {
      var owner = this.get('repo.ownerName');
      var repo = this.get('repo.vcsName');
      var vcsType = this.get('repo.vcsType');
      var vcsId = this.get('repo.vcsId');
      var slugOwner = this.get('repo.slug').split('/')[0];
      return this.externalLinks.repoUrl(vcsType, {
        owner: owner,
        repo: repo,
        vcsId: vcsId,
        slugOwner: slugOwner
      });
    }),
    orgBuildHistoryLink: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return this.externalLinks.orgBuildHistoryLink(slug);
    }),
    comBuildHistoryLink: Ember.computed('repo.slug', function () {
      var slug = this.get('repo.slug');
      return this.externalLinks.comBuildHistoryLink(slug);
    }),
    actions: {
      toggleStatusBadgeModal: function toggleStatusBadgeModal() {
        this.toggleProperty('isShowingStatusBadgeModal');
      },
      toggleTriggerBuildModal: function toggleTriggerBuildModal() {
        this.toggleProperty('isShowingTriggerBuildModal');
      }
    },
    didRender: function didRender() {
      var repo = this.get('repo');

      if (repo.hasBuildBackups === undefined) {
        repo.fetchInitialBuildBackups.perform();
      }

      var allowance = repo.get('allowance');
      var ownerRoMode = repo.get('owner').ro_mode || false;

      if (this.isProVersion && allowance && !repo.canOwnerBuild && this.auth.currentUser && this.auth.currentUser.confirmedAt) {
        var isUser = repo.ownerType === 'user';

        if (repo.private) {
          this.flashes.custom('flashes/negative-balance-private', {
            owner: repo.owner,
            isUser: isUser
          }, 'warning');
        } else {
          this.flashes.custom('flashes/negative-balance-public', {
            owner: repo.owner,
            isUser: isUser
          }, 'warning');
        }

        if (allowance.get('pendingUserLicenses')) {
          this.flashes.custom('flashes/pending-user-licenses', {
            owner: repo.owner,
            isUser: isUser
          }, 'warning');
        } else if (!allowance.get('userUsage')) {
          this.flashes.custom('flashes/users-limit-exceeded', {
            owner: repo.owner,
            isUser: isUser
          }, 'warning');
        }
      } else if (this.userRoMode && ownerRoMode) {
        this.flashes.custom('flashes/read-only-mode', {}, 'warning');
      } else {
        this.flashes.removeCustomsByClassName('warning');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.flashes.removeCustomsByClassName('warning');
    }
  });

  _exports.default = _default;
});